import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from "@angular/core";
import * as _ from "lodash";
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
  
} from "@angular/cdk/drag-drop";
import { TaskComponent } from "../task";
import { Observable, Subscription } from "rxjs";

declare type Column = {
  image: string;
  item: string;
}

export declare type FillingimageData = {
  type?: string;
  question: string;
  columns: Column[];
};

@Component({
  selector: "app-fillingimage",
  templateUrl: "./fillingimage.component.html",
  styleUrls: ["./fillingimage.component.css"]
})
export class FillingimageComponent implements TaskComponent {
  @Input() data: FillingimageData;
  @Output() taskSubmitted: EventEmitter<boolean> = new EventEmitter();
  @Input() submitTask?: Observable<void>;
  private eventsSubscription: Subscription;
  public items: string[];
  public isCorrect: boolean[];
  public isAnswered: boolean = false;

  constructor() {}

  ngOnInit() {
    this.eventsSubscription = this.submitTask.subscribe(() => this.submit());
    this.items = [];
    this.isCorrect = [];
    for(let i in this.data.columns) {
      this.items.push("");
      this.isCorrect.push(false);
    }
    console.log(this.items);
  }

  submit() {
    let isCorrect = true;
    for(let i in this.data.columns) {
        console.log(this.data.columns[i].item, this.items[i])
        if(this.items[i] != this.data.columns[i].item) {
          isCorrect = false;
          this.isCorrect[i] = false;
        } else {
          this.isCorrect[i] = true;
        }
    }
    this.isAnswered = true;
    this.taskSubmitted.next(isCorrect);
  }

  ngOnDestroy() {
    this.eventsSubscription.unsubscribe();
  }
}
