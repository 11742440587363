import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { TaskComponent } from "../task";
import { Paragraph } from "src/app/dvz/dikobraz-3d-tiskne-a-modeluje/models/task.model";
import * as _ from "lodash";
import { compareStrings } from "@shared/utilities/compare-strings";

declare type Option = { numberVariant: string; textualVariant: string };

export declare type OrdinalNumbersData = {
  type?: string;
  question: Paragraph[];
  numbers: {
    number: number;
    options: Option[];
  }[];
};

@Component({
  selector: "app-ordinalnumbers",
  templateUrl: "./ordinalnumbers.component.html",
  styleUrls: ["./ordinalnumbers.component.css"],
})
export class OrdinalNumbersComponent implements TaskComponent, OnInit {
  @Input() data: OrdinalNumbersData;
  @Output() taskSubmitted: EventEmitter<boolean> = new EventEmitter();
  public answers: string[] = [];
  public isAnswered: boolean = false;
  private isTaskCorrect: boolean = true;
  public questions: string[] = [];
  public currentIndex: number = 0;
  public usedOption: Option;
  public indexOfUsedOption: number;

  constructor() {}

  ngOnInit() {
    this.usedOption = _.shuffle(
      this.data.numbers[this.currentIndex].options
    )[0];
    this.indexOfUsedOption = this.sortOptions().indexOf(this.usedOption);
    this.createQuestion();
  }

  sortOptions(): Option[] {
    return _.sortBy(
      this.data.numbers[this.currentIndex].options,
      "numberVariant"
    );
  }

  createQuestion() {
    const sortedOptions = this.sortOptions();
    this.usedOption = sortedOptions[this.indexOfUsedOption];
    this.questions = [this.usedOption.textualVariant];
    const shuffled = sortedOptions.sort(() => 0.5 - Math.random());
    let selected: string[] = [];
    selected = shuffled
      .filter(
        (element) =>
          element.textualVariant !== this.usedOption.textualVariant &&
          !selected.includes(element.textualVariant)
      )
      .slice(0, 2) // get only 2 incorrect options
      .map((selected) => selected.textualVariant);
    this.questions = _.shuffle(this.questions.concat(selected));
  }

  check(answer: string) {
    if (!compareStrings(answer, this.usedOption.textualVariant)) {
      this.isTaskCorrect = false;
    }
    this.isAnswered = true;
    console.log(this.data.numbers.length - 1 === this.currentIndex);
  }

  setColor(answer: string) {
    if (!this.isAnswered) {
      return "";
    }
    if (compareStrings(answer, this.usedOption.textualVariant)) {
      return "correct";
    } else {
      return "incorrect";
    }
  }

  next() {
    this.currentIndex++;
    this.isAnswered = false;
    this.createQuestion();
  }

  submit() {
    this.taskSubmitted.emit(this.isTaskCorrect);
  }
}
