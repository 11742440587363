import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { TaskComponent } from "../task";
import { Paragraph } from "src/app/dvz/dikobraz-3d-tiskne-a-modeluje/models/task.model";
import * as _ from "lodash";

declare type Question = {
  pairId: number;
  isPicture: boolean;
  content: string;
  isSelected: boolean;
  isAnswered: boolean;
};

export declare type PexesoData = {
  type?: string;
  isTimeMeasure?: boolean;
  question: Paragraph[];
  mainImageUrl: string;
  questions: {
    text: string;
    imageUrl: string;
  }[];
};

@Component({
  selector: "app-pexeso",
  templateUrl: "./pexeso.component.html",
  styleUrls: ["./pexeso.component.css"],
})
export class PexesoComponent implements TaskComponent, OnInit {
  @Input() data: PexesoData;
  @Output() taskSubmitted: EventEmitter<boolean> = new EventEmitter();

  public isAnswering: boolean = false;
  public isAnswered: boolean = false;
  public answeredQuestions: Question[] = [];
  public questions: Question[] = [];
  public size: number;
  public mainSize: number = 600;
  public cols: number;
  public startTime: number;
  public endTime: number;

  constructor() {}

  ngOnInit() {
    let i = 0;
    this.data.questions.forEach((question) => {
      this.questions.push({
        pairId: i,
        isPicture: true,
        content: question.imageUrl,
        isSelected: false,
        isAnswered: false,
      });
      this.questions.push({
        pairId: i,
        isPicture: false,
        content: question.text,
        isSelected: false,
        isAnswered: false,
      });
      i++;
    });
    this.questions = _.shuffle(this.questions);
    this.cols =
      this.data.questions.length / 2 === 1 ? 2 : this.data.questions.length / 2;
    this.size = this.mainSize / this.cols;
    if (this.data.isTimeMeasure) {
      this.startTime = new Date().getTime();
    }
  }

  flip(question: Question) {
    if (this.answeredQuestions.length === 2) {
      this.closeQuestions();
    }
    question.isSelected = true;
    this.answeredQuestions.push(question);
    if (this.answeredQuestions.length === 2) {
      this.checkAnswer();
    }
  }

  checkAnswer() {
    if (this.answeredQuestions[0].pairId === this.answeredQuestions[1].pairId) {
      this.answeredQuestions.forEach(
        (question) => (question.isAnswered = true)
      );
    }
    if (_.every(this.questions, { isAnswered: true })) {
      if (this.data.isTimeMeasure) {
        console.log(new Date().getTime() - this.startTime);
      }
      this.taskSubmitted.emit(true);
      this.isAnswered = true;
    }
  }

  closeQuestions() {
    this.answeredQuestions.forEach((question) => (question.isSelected = false));
    this.answeredQuestions = [];
  }

  setVisibility(question: Question) {
    if (question.isAnswered) {
      return "answered";
    } else if (question.isSelected) {
      return "visible";
    } else {
      return "non-visible";
    }
  }
}
