import { DOCUMENT } from '@angular/common';
import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef, Inject, InjectionToken, ViewEncapsulation } from '@angular/core';
import { Paragraph } from 'src/app/dvz/dikobraz-3d-tiskne-a-modeluje/models/task.model';
import { TaskComponent } from '../task';
import * as _ from "lodash";
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';

export declare type TabledragData = {
  type?: string;
  question: Paragraph[];
  rows: TableCell[][];
};

export declare type TableCell = TableCellImage | TableCellText | TableCellDrag;

export interface TableCellText {
  type: string;
  text: string;
}

export interface TableCellImage {
  type: string;
  url: string;
}


export interface TableCellDrag{
  type: string;
  correct: Paragraph;
  beforeText: string;
  afterText: string;
}


@Component({
  selector: 'app-tabledrag',
  templateUrl: './tabledrag.component.html',
  styleUrls: ['./tabledrag.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class TabledragComponent implements TaskComponent, OnInit {

  @Input() data: TabledragData;
  @Output() taskSubmitted: EventEmitter<boolean> = new EventEmitter();
  public answer: string;
  public isAnswered = false;
  public isCorrect: boolean[] = [];
  private document;
  public options: {id: string, val:Paragraph}[] = [];
  public dragIns: {[id: string]:{id: string, val:Paragraph}[]} = {};

  constructor(@Inject(DOCUMENT) document, private changeRef: ChangeDetectorRef) { 
    this.document = document;
  }

  ngOnInit() {
    this.options = [];
    _.each(this.data.rows, (row: TableCell[], y)  => {
      _.each(row, (col: TableCell, x) => {
        if(col.type == "drag") {
          this.options.push({
            id: 'col-' + y + '-' + x,
            val: (col as TableCellDrag).correct
          });
        }
      });
    });
    this.options = _.shuffle(this.options);
    for(let option of this.options) {
      this.dragIns[option.id] = [];
    }
  }

  ngOnChanges() {
    this.options = [];
    console.log(this.data.rows);
    _.each(this.data.rows, (row: TableCell[], y)  => {
      _.each(row, (col: TableCell, x) => {
        if(col.type == "drag") {
          this.options.push({
            id: 'col-' + y + '-' + x,
            val: (col as TableCellDrag).correct
          });
        }
      });
    });
    this.options = _.shuffle(this.options);
    for(let option of this.options) {
      this.dragIns[option.id] = [];
    }
    console.log(this.options);
  }

  drop(event: CdkDragDrop<{id: string, val:Paragraph}[]>) {
    if (event.previousContainer === event.container) {
      console.log("SAME", event.previousContainer, event.container)
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      console.log("DIFF")
      if(event.container.data.length == 0 || event.container.id == "options") {
        transferArrayItem(event.previousContainer.data,
          event.container.data,
          event.previousIndex,
          event.currentIndex);
      }
    }
  }

  isAllFilled() {
    return _.every(this.dragIns, (dragIn) => dragIn.length > 0) && this.options.length == 0;
  }

  submit() {
    this.isCorrect = [];
    _.each(this.data.rows, (row: TableCell[], y)  => {
      _.each(row, (col: TableCell, x) => {
        if(col.type == 'drag') {
          let correctAns = this.dragIns[ 'col-' + y + '-' + x][0].id ==  'col-' + y + '-' + x;
          this.isCorrect.push(correctAns);
          let inputEl = this.document.querySelector('div#' + this.dragIns[ 'col-' + y + '-' + x][0].id);
          inputEl.classList.add(correctAns ? 'correct' : 'incorrect', 'answer');
        }
      })
    })
    if(_.every(this.isCorrect)) {
      this.taskSubmitted.emit(true);
    } else {
      this.taskSubmitted.emit(false);
    }
    this.isAnswered = true;
  }

}

