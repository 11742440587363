import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { TaskComponent } from "../task";
import { Paragraph } from "src/app/dvz/dikobraz-3d-tiskne-a-modeluje/models/task.model";
import * as _ from "lodash";

declare type Word = { text: string; audio: string };

export declare type FlyingwordsData = {
  type?: string;
  minimum?: number;
  time?: number;
  question: Paragraph[];
  words: Word[];
};

@Component({
  selector: "app-flyingwords",
  templateUrl: "./flyingwords.component.html",
  styleUrls: ["./flyingwords.component.css"],
})
export class FlyingwordsComponent implements TaskComponent, OnInit {
  @Input() data: FlyingwordsData;
  @Output() taskSubmitted: EventEmitter<boolean> = new EventEmitter();
  public words: Word[];
  public results: boolean[];
  public activeWord: Word;
  public currentIndex: number;
  private audio: HTMLAudioElement;
  public isStart: boolean = false;
  public startTime: number;
  public isBegin: boolean;
  public isAnswered: boolean;
  public message: string;

  constructor() {}

  ngOnInit() {
    this.setUp();
  }

  setUp() {
    this.isAnswered = false;
    this.isBegin = true;
    this.words = _.shuffle(this.data.words);
    this.results = [];
    this.currentIndex = 0;
    this.activeWord = this.words[this.currentIndex];
  }

  next(word: Word) {
    this.isStart = false;
    this.stopAudio();
    const isInTime = this.data.time
      ? Date.now() - this.startTime < this.data.time * 1000
      : true;
    const isCorrect = isInTime && word.text === this.activeWord.text;
    this.results.push(isCorrect);
    this.message = isCorrect
      ? "Správná odpověď!"
      : `Špatně, správná odpověď: ${this.activeWord.text} (nebo jsi nestihl čas. limit)`;
    if (!this.words[this.currentIndex + 1]) {
      this.submit();
    } else {
      this.currentIndex++;
      this.activeWord = this.words[this.currentIndex];
    }
  }

  startAudio() {
    this.audio = new Audio(this.activeWord.audio);
    this.audio.play();
    this.startTime = Date.now();
  }

  stopAudio() {
    if (this.audio) {
      this.audio.pause();
      this.audio.remove();
    }
  }

  start() {
    this.message = "";
    this.isStart = true;
    this.isBegin = false;
    this.startAudio();
  }

  submit() {
    let success = this.data.minimum ? false : true;
    if (!success) {
      let counter = 0;
      for (let result of this.results) {
        if (result) counter++;
        if (counter === this.data.minimum) {
          success = true;
          break;
        }
      }
    }
    if (success) {
      this.isAnswered = true;
      this.taskSubmitted.emit(true);
    } else {
      this.message += "\nBohužel musíš tohle cvičení opakovat.";
      this.setUp();
    }
  }
}
