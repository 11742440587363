import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { TaskComponent } from "../task";
import { Paragraph } from "src/app/dvz/dikobraz-3d-tiskne-a-modeluje/models/task.model";
import * as _ from "lodash";
import { compareStrings } from "@shared/utilities/compare-strings";

declare type Question = {
  pairId: number;
  isPicture: boolean;
  content: string;
  isSelected: boolean;
  isAnswered: boolean;
  answer: string;
  isCorrect: boolean;
  input: string;
};

export declare type PexesoFillData = {
  type?: string;
  isTimeMeasure?: boolean;
  question: Paragraph[];
  mainImageUrl: string;
  isFrKey?: boolean;
  questions: {
    answer: string;
    imageUrl: string;
  }[];
};

@Component({
  selector: "app-pexesofill",
  templateUrl: "./pexesofill.component.html",
  styleUrls: ["./pexesofill.component.css"],
})
export class PexesoFillComponent implements TaskComponent, OnInit {
  @Input() data: PexesoFillData;
  @Output() taskSubmitted: EventEmitter<boolean> = new EventEmitter();

  public isAnswering: boolean = false;
  public isAnswered: boolean = false;
  public answeredQuestions: Question[] = [];
  public questions: Question[] = [];
  public size: number;
  public mainSize: number = 600;
  public cols: number;
  public startTime: number;
  public endTime: number;
  public focusTarget: { target: any; index: number };

  constructor() {}

  ngOnInit() {
    let i = 0;
    this.data.questions.forEach((question) => {
      this.questions.push({
        pairId: i,
        isPicture: true,
        content: question.imageUrl,
        isSelected: false,
        isAnswered: false,
        isCorrect: false,
        answer: question.answer,
        input: "",
      });
      i++;
    });
    this.questions = _.shuffle(this.questions);
    this.cols = Math.sqrt(this.data.questions.length);

    this.size = this.mainSize / this.cols;
    if (this.data.isTimeMeasure) {
      this.startTime = new Date().getTime();
    }
  }

  flip(question: Question) {
    this.closeQuestions();
    question.isSelected = true;
  }

  checkAnswer(question: Question) {
    question.isCorrect = compareStrings(question.answer, question.input);
    question.isAnswered = true;
    if (_.every(this.questions, { isAnswered: true })) {
      if (this.data.isTimeMeasure) {
        console.log(new Date().getTime() - this.startTime);
      }
      this.taskSubmitted.emit(_.every(this.questions, { isCorrect: true }));
      this.isAnswered = true;
    }
  }

  onKey(event: any, index: number) {
    this.questions[index].input = event.target.value;
  }

  onClick($event: { value: string }) {
    this.focusTarget.target.value += $event.value;
    this.onKey(this.focusTarget, this.focusTarget.index);
  }

  onFocusEvent(event: any, i: number) {
    this.focusTarget = { target: event.target, index: i };
  }

  setColor(question: Question) {
    if (!question.isAnswered) {
      return "";
    }
    if (question.isCorrect) {
      return "correct";
    } else {
      return "incorrect";
    }
  }

  closeQuestions() {
    this.questions.forEach((question) => {
      if (!question.isAnswered) question.isSelected = false;
    });
  }

  setVisibility(question: Question) {
    if (question.isAnswered) {
      return "answered";
    } else if (question.isSelected) {
      return "visible";
    } else {
      return "non-visible";
    }
  }
}
