import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { TaskComponent } from "../task";
import * as _ from "lodash";

declare type Pair = {
  text: string;
  imageUrl?: string;
  alterText?: string;
};

export declare type ColumnPexesoData = {
  type?: string;
  pairs: Pair[];
};

@Component({
  selector: "app-columnpexeso",
  templateUrl: "./columnpexeso.component.html",
  styleUrls: ["./columnpexeso.component.css"],
})
export class ColumnPexesoComponent implements TaskComponent, OnInit {
  @Input() data: ColumnPexesoData;
  @Output() taskSubmitted: EventEmitter<boolean> = new EventEmitter();
  
  public imageCol: Pair[];
  public textCol: Pair[];
  public selectedText: Pair;
  public selectedImg: Pair;
  public answered: Pair[] = [];

  constructor() {}

  ngOnInit() {
    this.textCol = _.shuffle(this.data.pairs);
    this.imageCol = _.shuffle(this.data.pairs);
  }

  flipText(pair: Pair) {
    if (!this.selectedText) {
      this.selectedText = pair;
      if (this.selectedImg) {
        this.checkAnswer();   
      }
    }
  }

  flipImg(pair: Pair) {
    if (!this.selectedImg) {
      this.selectedImg = pair;
      if (this.selectedText) {
        this.checkAnswer();   
      }
    }
  }

  isVisibleText(pair: Pair) {
    return this.answered.includes(pair) || this.selectedText == pair;
  }

  isVisibleImg(pair: Pair) {
    return this.answered.includes(pair) || this.selectedImg == pair;
  }

  checkAnswer() {
    if (this.selectedText == this.selectedImg) {
      this.answered.push(this.selectedText);
    }
    if(this.answered.length == this.data.pairs.length) {
      this.taskSubmitted.next(true);
    }
    setTimeout(()=> {
      this.selectedText = null;
      this.selectedImg = null;
    },2000);
  }

}
