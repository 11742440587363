import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from "@angular/core";
import * as _ from "lodash";
import { Paragraph } from "src/app/dvz/dikobraz-3d-tiskne-a-modeluje/models/task.model";

declare type TruefalseStatement = {
  isTrue: boolean;
  text: Paragraph;
};

export declare type TruefalseData = {
  type?: string;
  question: Paragraph[];
  statements: TruefalseStatement[];
};

declare type TrueFalseResults = {
  isAnswered: boolean;
  isCorrect: boolean;
  answer: boolean;
}[];

@Component({
  selector: "app-truefalse",
  templateUrl: "./truefalse.component.html",
  styleUrls: ["./truefalse.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class TruefalseComponent implements OnInit {
  @Input() data: TruefalseData;
  @Output() taskSubmitted: EventEmitter<boolean> = new EventEmitter();
  _ = _;
  public statements: TruefalseStatement[] = [];
  public results: TrueFalseResults = [];
  public isAnswered: boolean = false;
  private isCorrect: boolean[] = [];

  constructor() {}

  ngOnInit() {
    console.log(this.data);
    this.data.statements.forEach((statement) =>
      this.results.push({ isAnswered: false, isCorrect: false, answer: false })
    );
  }

  setColor(i: number, isTrueButton: boolean) {
    if (
      !this.results[i].isAnswered ||
      isTrueButton !== this.results[i].answer
    ) {
      return "";
    }
    if (this.results[i].isCorrect) {
      return "correct";
    } else {
      return "incorrect";
    }
  }

  submit(i: number, answer: boolean) {
    this.results[i].isAnswered = true;
    this.results[i].answer = answer;
    this.results[i].isCorrect = this.data.statements[i].isTrue === answer;
    this.isCorrect.push(this.results[i].isCorrect);
    if (this.isCorrect.length === this.results.length) {
      if (_.every(this.isCorrect)) {
        this.taskSubmitted.emit(true);
      } else {
        this.taskSubmitted.emit(false);
      }
      this.isAnswered = true;
    }
  }
}
