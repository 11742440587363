import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-fr-keyboard",
  templateUrl: "./fr-keyboard.component.html",
  styleUrls: ["./fr-keyboard.component.css"],
})
export class FrKeyboardComponent implements OnInit {
  @Input() focusTarget: { target: any };
  @Input() isDisabled: boolean;

  @Output() clickResult: EventEmitter<{
    value: string;
  }> = new EventEmitter();

  public chars: string[] = [
    "ê",
    "ë",
    "è",
    "ç",
    "à",
    "â",
    "ô",
    "ù",
    "û",
    "î",
    "ï",
    "œ",
    "’",
  ];

  constructor() {}

  ngOnInit() {}

  click(char: string) {
    if (this.focusTarget) {
      this.clickResult.emit({ value: char });
    }
  }
}
