import { Component, Input, OnInit } from '@angular/core';
import { Paragraph } from 'src/app/dvz/dikobraz-3d-tiskne-a-modeluje/models/task.model';

@Component({
  selector: 'app-paragraphs',
  templateUrl: './paragraphs.component.html',
  styleUrls: ['./paragraphs.component.scss']
})
export class ParagraphsComponent implements OnInit {
  @Input() paragraphs: Paragraph[] | string;

  constructor() { }

  ngOnInit() {
  }

  isString() {
    return typeof this.paragraphs === 'string';
  }
}
