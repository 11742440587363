import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TaskComponent } from '../task';
import * as _ from "lodash";
import { Howl } from 'howler';


export declare type SingleaudioTextData = {
  type?: string;
  question: string;
  audio: string | Howl;
  options: SingleaudioTextOption[];
};

export interface SingleaudioTextOption {
  text: string;
  correct: boolean;
}

@Component({
  selector: 'app-singleaudiotext',
  templateUrl: './singleaudiotext.component.html',
  styleUrls: ['./singleaudiotext.component.css']
})
export class SingleAudioTextComponent implements TaskComponent, OnInit {

  @Input() data: SingleaudioTextData;
  @Output() taskSubmitted: EventEmitter<boolean> = new EventEmitter();
  public answer: boolean[];
  public isCorrect: boolean[];
  public isAnswered: boolean = false;
  
  constructor() { 

  }

  ngOnInit() {
    if(!this.answer) {
      this.answer = new Array(this.data.options.length).fill(false);
    }
    this.data.audio = new Howl({
      src: [ this.data.audio ]
    });
  }

  submit(i) {
    this.answer[i] = true;
    this.isCorrect = [];
    for(let answerIndex in this.answer) {
      this.isCorrect.push(this.answer[answerIndex] == this.data.options[answerIndex].correct);
    }
    this.taskSubmitted.emit(_.every(this.isCorrect));
    this.isAnswered = true;
  }


}
