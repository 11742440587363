import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from "@angular/core";
import * as _ from "lodash";
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
  
} from "@angular/cdk/drag-drop";
import { TaskComponent } from "../task";
import { Observable, Subscription } from "rxjs";

declare type Column = {
  image: string;
  item: string;
}

export declare type MatchingdragimageData = {
  type?: string;
  question: string;
  hideNumbers: boolean;
  columns: Column[];
};

@Component({
  selector: "app-matchingdragimage",
  templateUrl: "./matchingdragimage.component.html",
  styleUrls: ["./matchingdragimage.component.css"]
})
export class MatchingdragimageComponent implements TaskComponent {
  @Input() data: MatchingdragimageData;
  @Output() taskSubmitted: EventEmitter<boolean> = new EventEmitter();
  @Input() submitTask?: Observable<void>;
  private eventsSubscription: Subscription;
  public items: string[][];
  public unsortedItems: string[];
  public isCorrect: boolean[];
  public isAnswered: boolean = false;

  constructor() {}

  ngOnInit() {
    this.eventsSubscription = this.submitTask.subscribe(() => this.submit());
    this.unsortedItems = _.shuffle(_.map(this.data.columns, (column: Column) => column.item));
    this.items = [];
    this.isCorrect = [];
    for(let i in this.data.columns) {
      this.items.push([]);
      this.isCorrect.push(false);
    }
    console.log(this.items);
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
  }

  submit() {
    let isCorrect = true;
    for(let i in this.data.columns) {
        console.log(this.data.columns[i].item, this.items[i])
        if(!this.items[i].includes(this.data.columns[i].item)) {
          isCorrect = false;
          this.isCorrect[i] = false;
        } else {
          this.isCorrect[i] = true;
        }
    }
    this.isAnswered = true;
    this.taskSubmitted.next(isCorrect);
  }

  ngOnDestroy() {
    this.eventsSubscription.unsubscribe();
  }
}
