import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TaskComponent } from '../task';
import * as _ from "lodash";
import { Observable, Subscription } from 'rxjs';

export declare type MultichoiceData = {
  type?: string;
  question: string;
  options: MultichoiceOption[];
};

export interface MultichoiceOption {
  text: string;
  correct: boolean;
}

@Component({
  selector: 'app-multichoice',
  templateUrl: './multichoice.component.html',
  styleUrls: ['./multichoice.component.css']
})
export class MultichoiceComponent implements TaskComponent, OnInit {

  @Input() data: MultichoiceData;
  @Input() submitTask: Observable<void>;
  private eventsSubscription: Subscription;
  @Output() taskSubmitted: EventEmitter<boolean> = new EventEmitter();
  public answer: boolean[];
  public isCorrect: boolean[];
  public isAnswered: boolean = false;
  
  constructor() { }

  ngOnInit() {
    if(!this.answer) {
      this.answer = new Array(this.data.options.length).fill(false);
    }
    this.eventsSubscription = this.submitTask.subscribe(() => this.submit())
  }

  submit() {
    this.isCorrect = [];
    for(let answerIndex in this.answer) {
      this.isCorrect.push(this.answer[answerIndex] == this.data.options[answerIndex].correct);
    }
    this.taskSubmitted.emit(_.every(this.isCorrect));
    this.isAnswered = true;
  }

  getPoints() {
    return Math.round(_.filter(this.isCorrect).length / this.isCorrect.length * this.data.options.length);
  }

  getInflection(points: number) {
    return points === 1 ? '' : (points > 1 && points < 5 ? 'y' : 'ů');
  }

  getOptionColor(index) {
    if(this.isAnswered == false) {
      return [];
    }
    if (this.answer[index] == this.data.options[index].correct) {
      return ['correct'];
    }
    return ['incorrect'];
  }

  getCorrectAnswer(index) {
    if(this.isAnswered == false) {
      return '';
    }
    if (this.answer[index] == this.data.options[index].correct) {
      return 'Správně!';
    }
    return 'Špatně!';
  }

  ngOnDestroy() {
    this.eventsSubscription.unsubscribe();
  }


}
