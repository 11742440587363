import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {
  constructor(private http: HttpClient, private activatedRoute: ActivatedRoute) { 
    this.activatedRoute.params.subscribe((params: Params) => {
      if(params["dvz"]) {
        this.http.get('/assets/dvz/'+params['dvz']+'/data-description.json').subscribe((data) => {
          this.endpoints = data['endpoints'];
          this.endpointSchemes = data['endpoint-schemes'];
        })
      }
    });
  }
  public endpoints = []
  public endpointSchemes = {};
  public base = environment.server + '/';

  ngOnInit() {

  }
}
