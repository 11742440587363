import { Component, OnInit, Input, Output, EventEmitter, ViewChildren, TemplateRef, ElementRef, QueryList, Inject, ChangeDetectorRef, ViewEncapsulation, OnChanges, SimpleChange } from '@angular/core';
import { TaskComponent } from '../task';
import * as _ from "lodash";
import { Observable, Subscription } from 'rxjs';
import { Howl } from "howler";

export declare type Content = {
  type: "image" | "text";
  content: string;
}
export declare type MultilineaudiochoiceLine = {
  audio: string;
  correct: string;
  options: string;
}

export declare type MultilineaudiochoiceData = {
  type?: string;
  question?: string;
  columnCount: number;
  lines: MultilineaudiochoiceLine[];
};

@Component({
  selector: "app-multilineaudiochoice",
  templateUrl: "./multilineaudiochoice.component.html",
  styleUrls: ["./multilineaudiochoice.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class MultilineaudiochoiceComponent implements TaskComponent, OnInit {
  @Input() data: MultilineaudiochoiceData;
  @Output() taskSubmitted: EventEmitter<boolean> = new EventEmitter();
  public isAnswered = false;
  @Input() submitTask: Observable<void>;
  private eventsSubscription: Subscription;
  public isCorrect = false;
  public answers: string[] = [];
  public lines: MultilineaudiochoiceLine[] = [];
  public recordings: Howl[] = [];

  constructor() {

  }

  ngOnInit() {
    this.eventsSubscription = this.submitTask.subscribe(() => this.submit());
    this.lines = this.data.lines;
    this.answers = Array(this.lines.length).fill("");
    this.recordings = this.lines.map((line) => new Howl({src: [line.audio]}))
  }

  ngOnChanges() {
    this.lines = this.data.lines;
    this.answers = Array(this.lines.length).fill("");
    this.recordings = this.lines.map((line) => new Howl({src: [line.audio]}))
  }

  ngOnDestroy() {
    this.eventsSubscription.unsubscribe();
  }


  submit() {
    console.log("SUBMITTED")
    this.isCorrect = true;
    for (let answerIndex in this.answers) {
      if(this.answers[answerIndex] != this.lines[answerIndex].correct) {
        this.isCorrect = false;
      }
    }
    this.taskSubmitted.emit(this.isCorrect);
    this.isAnswered = true;
  }
}
