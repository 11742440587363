// Modules 3rd party
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

// 404 page
import { PageNotFoundComponent } from './pages/not-found/not-found.component';

// Pages
import { HomeComponent } from './pages/home/home.component';
import { AboutMeComponent } from './pages/about-me/about-me.component';

// Components
import { MiscComponent } from './components/misc/misc.component';

// Protected
import { CourseComponent } from './pages/course/course.component';
import { LearnComponent } from './pages/learn/learn.component';

// Routing
const appRoutes: Routes = [

  // Public pages
  { path: '', redirectTo: '/dvz', pathMatch : 'full' },
  { path: 'o-projektu', component: HomeComponent },
  { path: 'o-nas', component: AboutMeComponent },
  { path: 'metodiky', component: LearnComponent },
  { path: 'misc', component: MiscComponent },

  // Protected pages
  // { path: 'profile/:uid/:name', component: ProfileComponent, canActivate: [AuthGuardService] },
  { path: 'dvz',
    children: [
      { path: '', component: CourseComponent }
    ]
  },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule]
})

export class AppRoutingModule {
}
