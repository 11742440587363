import { Component, OnInit, Input, Output, EventEmitter, Inject, ChangeDetectorRef, ViewEncapsulation, OnChanges } from '@angular/core';
import { TaskComponent } from '../task';
import { DOCUMENT } from '@angular/common';
import * as _ from "lodash";
import { Howl } from 'howler';

export declare type MultimultifillData = {
  type?: string;
  question: string;
  lines: {
    template: string;
    audio: string | Howl;
  }[];
};

@Component({
  selector: "app-multimultifill",
  templateUrl: "./multimultifill.component.html",
  styleUrls: ["./multimultifill.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class MultimultifillComponent implements TaskComponent, OnInit, OnChanges {
  @Input() data: MultimultifillData;
  @Output() taskSubmitted: EventEmitter<boolean> = new EventEmitter();
  public isAnswered = false;
  public answered: boolean[] = [];
  public isCorrect: boolean[] = [];

  constructor(
  ) {

  }

  ngOnInit() {
    this.isCorrect = new Array(this.data.lines.length).fill(false);
    this.answered = new Array(this.data.lines.length).fill(false);
    for(let i in this.data.lines) {
      this.data.lines[i].audio = new Howl({
        src: [ this.data.lines[i].audio ]
      });
    }
  }

  ngOnChanges() {
    this.isCorrect = new Array(this.data.lines.length).fill(false);
    this.answered = new Array(this.data.lines.length).fill(false);
    for(let i in this.data.lines) {
      this.data.lines[i].audio = new Howl({
        src: [ this.data.lines[i].audio ]
      });
    }
  }

  evaluate(correct: boolean, i: number) {
    this.isCorrect[i] = correct;
    this.answered[i] = true;
    if(_.every(this.answered)) {
      if (_.every(this.isCorrect)) {
        this.taskSubmitted.emit(true);
      } else {
        this.taskSubmitted.emit(false);
      }
      this.isAnswered = true;
    }
  }

  public fromLine(line: {
    template: string;
    audio: string;
  }) {
    return {
      question: null,
      template: line.template,
      answers: null
    }
  }
}
