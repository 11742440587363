import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewEncapsulation,
} from "@angular/core";
import * as _ from "lodash";
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from "@angular/cdk/drag-drop";
import { Observable, Subscription } from "rxjs";
import { matchAll } from "@shared/utilities";

export declare type MultidragData = {
  question?: string;
  template: string;
  type: string;
};

export declare type MultidragPart = {
  type: MultidragPartType;
  content: string;
};

export enum MultidragPartType {
  TEXT,
  GAP,
}

@Component({
  selector: "app-multidrag",
  templateUrl: "./multidrag.component.html",
  styleUrls: ["./multidrag.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class MultidragComponent implements OnChanges {
  @Input() data: MultidragData;
  @Output() taskSubmitted: EventEmitter<boolean> = new EventEmitter();
  @Output() isEmpty: EventEmitter<boolean> = new EventEmitter();
  @Input() submitTask: Observable<void>;
  public eventsSubscription: Subscription;
  partType = MultidragPartType;
  public dragIns: { [id: string]: { id: string; value: string }[] } = {};
  public isAnswered: boolean = false;
  private isCorrect: boolean[] = [];
  public parts: MultidragPart[] = [];
  public options: { id: string; value: string }[] = [];

  constructor(private changeRef: ChangeDetectorRef) {}

  ngOnInit() {
    if(this.data && this.data.template) {
      this.createGaps();
    }
    console.log(this.options);
    for (let option of this.options) {
      this.dragIns[option.id] = [];
    }
    this.eventsSubscription = this.submitTask.subscribe(() => this.submit())
  }

  ngOnChanges() {
    if(this.data && this.data.template) {
      this.createGaps();
    }
    console.log(this.options);
    for (let option of this.options) {
      this.dragIns[option.id] = [];
    }
    this.eventsSubscription = this.submitTask.subscribe(() => this.submit())
  }

  drop(event: CdkDragDrop<{ id: string; value: string }[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      if (event.container.data.length == 0 || event.container.id == "options") {
        transferArrayItem(
          event.previousContainer.data,
          event.container.data,
          event.previousIndex,
          event.currentIndex
        );
      }
    }
    if(this.options.length == 0) {
      this.isEmpty.next(true);
    } else {
      this.isEmpty.next(false);
    }
  }

  submit() {
    this.isCorrect = [];
    for (let i in this.parts) {
      if (this.parts[i].type == MultidragPartType.GAP) {
        console.log(this.parts[i].content);
        let correctAns =
        this.dragIns[this.parts[i].content][0] && this.dragIns[this.parts[i].content][0].id ==
          this.parts[i].content;
        this.isCorrect.push(correctAns);
        if (correctAns) {
          this.parts[i] = {
            type: MultidragPartType.TEXT,
            content:
              '<span class="answer correct">' +
              this.dragIns[this.parts[i].content][0].value +
              "</span>",
          };
        } else {
          let correctOption = _.find(this.options, (option) => option.id == this.parts[i].content)
          this.parts[i] = {
            type: MultidragPartType.TEXT,
            content:
              `<span class="answer incorrect">${this.dragIns[this.parts[i].content][0] ? this.dragIns[this.parts[i].content][0].value : ""}</span><span class="correct-answer">(${correctOption ? correctOption.value : ""})</span>`,
          };
        }
      }
    }
    if (_.every(this.isCorrect)) {
      this.taskSubmitted.emit(true);
    } else {
      this.taskSubmitted.emit(false);
    }
    this.isAnswered = true;
  }

  createGaps() {
    console.log(this.data);
    let gaps: { id: string; value: string }[] = []
    let gapMatches = matchAll(/\{#FILL:([^}*]+)\}/g, this.data.template);
    for(let i in gapMatches) {
      gaps.push({
        value: gapMatches[i][0].replace("{#FILL:", "").replace("}", ""),
        id: 'dragin-'+i
      });
    }
    let gappedText = this.data.template;
    for(let i in gaps) {
      gappedText = gappedText.replace(gapMatches[i][0], '{SPLIT}');
    }
    console.log(gappedText);
    let textParts = gappedText.split('{SPLIT}');
    console.log(textParts);
    let parts: MultidragPart[] = [];
    for(let i in textParts) {
      parts.push({
        type: MultidragPartType.TEXT,
        content: textParts[i]
      });
      if(parseInt(i) < textParts.length - 1) {
        parts.push({
          type: MultidragPartType.GAP,
          content: gaps[i].id
        });
      }
    }
    console.log(parts)
    this.parts = parts;
    this.options = _.shuffle(gaps);
  }
}
