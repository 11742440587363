import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
  MatButtonModule,
  MatCheckboxModule,
  MatMenuModule,
  MatInputModule,
  MatSnackBarModule,
  MatToolbarModule,
  MatDialogModule,
  MatSidenavModule,
  MatNativeDateModule,
  MatCardModule,
  MatTabsModule,
  MatIconModule,
  MatProgressSpinnerModule,
  MatGridListModule,
  MatRadioModule,
} from "@angular/material";
import { MatCarouselModule } from "@ngmodule/material-carousel";
import { BlocksModule } from "../components/blocks/blocks.module";
import { MiscModule } from "../components/misc/misc.module";
import { TasksModule } from "../tasks/tasks.module";
import { AdminComponent } from "./admin/admin.component";
import { DvzRoutingModule } from "./dvz-routing.module";

@NgModule({
  declarations: [AdminComponent],
  imports: [
    MatButtonModule,
    MatCheckboxModule,
    MatMenuModule,
    MatInputModule,
    MatSnackBarModule,
    MatToolbarModule,
    MatDialogModule,
    MatSidenavModule,
    MatNativeDateModule,
    MatCardModule,
    MatTabsModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatGridListModule,
    MatRadioModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BlocksModule,
    TasksModule,
    MiscModule,
    MatCarouselModule,
    CommonModule,
    FormsModule,
    DvzRoutingModule
  ],
  exports: [
    DvzRoutingModule
  ]
})
export class DvzModule {}
