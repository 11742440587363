import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewEncapsulation } from '@angular/core';
import * as _ from "lodash";
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Observable, Subscription } from 'rxjs';

export declare type OrderdraginlineData = {
  type?: string;
  question: string;
  sentences: string[][];
};

@Component({
  selector: 'app-orderdraginline',
  templateUrl: './orderdraginline.component.html',
  styleUrls: ['./orderdraginline.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class OrderdraginlineComponent implements OnChanges {

  @Input() data: OrderdraginlineData;
  @Input() submitTask: Observable<void>;
  private eventsSubscription: Subscription;
  @Output() taskSubmitted: EventEmitter<boolean> = new EventEmitter();
  public options: string[][] = [];
  public isAnswered: boolean = false;

  constructor() { 

  }

  ngOnInit() {
    console.log(this.data);
    this.options = _.map(this.data.sentences, (sentence: string[]) => _.shuffle(sentence));
    this.eventsSubscription = this.submitTask.subscribe(() => this.submit())
  }

  ngOnChanges() {
    console.log("CHANGE",this.data);
    this.options = _.map(this.data.sentences, (sentence: string[]) => _.shuffle(sentence));
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
  }
  
  submit() {
    this.isAnswered = true;
    let isCorrect = true;
    for(let i in this.data.sentences) {
      for(let j in this.data.sentences[i]) {
        if(this.options[i][j] != this.data.sentences[i][j]) {
          isCorrect = false
        }
      }
    }
    this.taskSubmitted.emit(isCorrect);
  }

  ngOnDestroy() {
    this.eventsSubscription.unsubscribe();
  }



}

