import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TaskComponent } from '../task';
import * as _ from "lodash";
import { Observable, Subscription } from 'rxjs';


export declare type OsmismerkaData = {
  type?: string;
  question: string;
  words: string[];
  rows: string[];
};

export interface Field {
  character: string;
  crossed: boolean;
}

export interface Word {
  word: string;
  crossed: boolean;
}

@Component({
  selector: 'app-osmismerka',
  templateUrl: './osmismerka.component.html',
  styleUrls: ['./osmismerka.component.css']
})
export class OsmismerkaComponent implements TaskComponent, OnInit {

  @Input() data: OsmismerkaData;
  @Output() taskSubmitted: EventEmitter<boolean> = new EventEmitter();
  @Input() submitTask: Observable<void>;
  private eventsSubscription: Subscription;
  public wordList: Word[] = []; 
  public isAnswered: boolean = false;
  public fields: Field[][] = [];
  public selected: [number, number][] = [];
  
  constructor() { 

  }

  ngOnInit() {
    this.eventsSubscription = this.submitTask.subscribe(() => this.send());
    this.fields = [];
    this.wordList = _.map(this.data.words, (word: string) => {
      return {
        word: word,
        crossed: false
      }
    });
    for(let row of this.data.rows) {
      let characters = row.split(",");
      this.fields.push(characters.map((val) => {
        return { 
          character: val.trim(),
          selected: false,
          crossed: false
        }
      }));
    }
  }

  select(i, j) {
    this.selected.push([i, j]);
  }

  isSelected(i, j) {
    return !!_.find(this.selected, (val) => val[0] == i && val[1] == j);
  }

  send() {
    let sortedCells = _.sortBy(this.selected, [0, 1]);
    let word = _.map(sortedCells, (cell: [number, number]) => this.fields[cell[0]][cell[1]].character).join("");
    let invertedWord = word.split("").reverse().join("");
    let wordIndex = _.findIndex(this.wordList, function(w: Word) {  return w.word.replace(/ /g,"").toUpperCase() == word.toUpperCase() || w.word.replace(/ /g,"").toUpperCase() == invertedWord.toUpperCase() });
    if(wordIndex != -1) {
      for(let item of this.selected) {
        this.fields[item[0]][item[1]].crossed = true
      }
      this.wordList[wordIndex].crossed = true;
    } else {

    }
    this.selected = [];
    if(_.every(this.wordList, (word) => word.crossed)){
      this.taskSubmitted.emit(true);
      this.isAnswered = true;
    }
  }

  canSend(): boolean {
    if(this.selected.length == 0 || this.isAnswered) {
      return false;
    }
    if(_.every(this.selected, (coords:[number, number]) => {
      console.log(coords[0], _.first(this.selected)[0], coords[0] == _.first(this.selected)[0])
      let x = coords[0];
      let y = coords[1];
      let x0 = _.first(this.selected)[0];
      let y0 = _.first(this.selected)[1];
      return x == x0 || y == y0 || x + y == x0 + y0 || x - y == x0 - y0;
    })) {
      return true;
    }
    return false;
  }

  ngOnDestroy() {
    this.eventsSubscription.unsubscribe();
  }


}
