import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Paragraph } from "src/app/dvz/dikobraz-se-uci-anglicky/models/task.model";
import { TaskComponent } from "../task";

export declare type FreecontentData = {
  type?: string;
  content: Paragraph[];
};

@Component({
  selector: "app-freecontent",
  templateUrl: "./freecontent.component.html",
  styleUrls: ["./freecontent.component.css"],
})
export class FreecontentComponent implements TaskComponent, OnInit {
  @Input() data: FreecontentData;
  @Output() taskSubmitted: EventEmitter<boolean> = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  next() {
    this.taskSubmitted.emit(true);
  }
}
