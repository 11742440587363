import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChange,
  ViewEncapsulation,
} from "@angular/core";
import * as _ from "lodash";
import { Observable, Subscription } from "rxjs";
import { Paragraph } from "src/app/dvz/dikobraz-3d-tiskne-a-modeluje/models/task.model";

declare type CrosswordOption = {
  czechWord: Paragraph;
  correct: string;
  numOfChar: number;
  showChars?: number[];
};

export declare type CrosswordData = {
  type?: string;
  question: Paragraph[];
  secret: string;
  isFrKey?: boolean;
  info: string;
  rows: CrosswordOption[];
};

declare type Result = {
  characters: string[];
  correct: boolean;
  secret: number;
};

@Component({
  selector: "app-crossword",
  templateUrl: "./crossword.component.html",
  styleUrls: ["./crossword.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class CrosswordComponent implements OnInit, OnChanges {
  @Input() data: CrosswordData;
  @Output() taskSubmitted: EventEmitter<boolean> = new EventEmitter();
  @Input() submitTask: Observable<void>;
  private eventsSubscription: Subscription;
  _ = _;
  public options: CrosswordOption[] = [];
  public crosswordResults: Result[] = [];
  public isAnswered: boolean = false;
  public focusTarget: { target: any; index: number };
  public secretPosition: number = 0;
  public positionCount: number = 0;
  public isCorrectAnswered: boolean = false;

  constructor() {}

  ngOnInit() {
    this.eventsSubscription = this.submitTask.subscribe(() => this.submit());
    if(this.data) {
      this.initCrossword();
    }
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }) {
    if(this.data && changes['data'] && changes['data'].previousValue != changes['data'].currentValue) {
      this.initCrossword();
    }
  }

  initCrossword() {
    this.isAnswered = false;
    this.crosswordResults  = [];
    this.isCorrectAnswered = false;
    console.log(this.data);
    let maxLeft = 0;
    let maxRight = 0;
    this.data.rows.forEach((row, i) => {
      if(row.numOfChar > maxLeft) {
        maxLeft = row.numOfChar;
      }
      if(row.correct.length - row.numOfChar > maxRight) {
        maxRight = row.correct.length - row.numOfChar;
      } 
      this.crosswordResults.push({
        characters: Array(row.correct.length).fill(""),
        secret: row.numOfChar - 1,
        correct: false
      });
      if(row.showChars && row.showChars.length > 0) {
        for(let char of row.showChars) {
          this.crosswordResults[i].characters[char - 1] = row.correct[char - 1];
        }
      }
    });
    this.secretPosition = maxLeft;
    this.positionCount = maxLeft + 1 + maxRight;
  }

  submit() {
    let correct = true;
    for(let i in this.crosswordResults) {
      if(this.crosswordResults[i].characters.join("").toUpperCase() != this.data.rows[i].correct.toUpperCase()) {
        this.crosswordResults[i].correct = false;
        correct = false;
      } else {
        this.crosswordResults[i].correct = true;
      }
    }
    this.taskSubmitted.emit(correct);
    this.isAnswered = true;
    this.isCorrectAnswered = correct;
  }

  ngOnDestroy() {
    this.eventsSubscription.unsubscribe();
  }
}
