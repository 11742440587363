import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewEncapsulation,
} from "@angular/core";
import * as _ from "lodash";
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from "@angular/cdk/drag-drop";
import { compareStrings } from "@shared/utilities/compare-strings";

export declare type InsertorderdragData = {
  type?: string;
  question: string;
  imageUrl?: string;
  options: string[];
};

declare type InsertResults = {
  [key: number]: string;
};

@Component({
  selector: "app-insertorderdrag",
  templateUrl: "./insertorderdrag.component.html",
  styleUrls: ["./insertorderdrag.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class InsertorderdragComponent implements OnChanges {
  @Input() data: InsertorderdragData;
  @Output() taskSubmitted: EventEmitter<boolean> = new EventEmitter();
  _ = _;
  public insertOptions: string[] = [];
  public dragOptions: string[] = [];
  public insertResults: InsertResults = {};
  public dragIns: string[][] = [];
  public isAnswered: boolean = false;
  private isCorrect: boolean[] = [];

  constructor(private changeRef: ChangeDetectorRef) {}

  ngOnInit() {
    console.log(this.data);
    [this.dragOptions, this.insertOptions] = this.splitArr(this.data.options);
    this.dragOptions = _.shuffle(this.dragOptions);
    for (let i in this.dragOptions) {
      this.dragIns[i] = [];
    }
  }

  ngOnChanges() {
    console.log("CHANGE", this.data);
    this.dragOptions = _.shuffle(this.dragOptions);
    for (let i in this.dragOptions) {
      this.dragIns[i] = [];
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      if (
        event.container.data.length == 0 ||
        event.container.id == "dragOptions"
      ) {
        transferArrayItem(
          event.previousContainer.data,
          event.container.data,
          event.previousIndex,
          event.currentIndex
        );
      }
    }
  }

  submit() {
    this.isCorrect = [];
    let correctAns;
    let i = 0;
    for (let option in this.data.options) {
      if (this.dragIns[i / 2]) {
        correctAns = this.dragIns[i / 2][0] == this.data.options[i];
      } else {
        correctAns =
          compareStrings(this.insertResults[i], this.data.options[i]) &&
          this.insertResults[i];
      }
      this.isCorrect.push(correctAns);
      i++;
    }
    if (_.every(this.isCorrect)) {
      this.taskSubmitted.emit(true);
    } else {
      this.taskSubmitted.emit(false);
    }
    this.isAnswered = true;
  }

  onKey(event: any, i: number) {
    this.insertResults[i] = event.target.value;
  }

  splitArr(array: any[]) {
    const arr1 = array.filter(function (value, index, Arr) {
      return index % 2 == 0;
    });
    const arr2 = array.filter((item) => !arr1.includes(item));
    return [arr1, arr2];
  }
}
