import { Component, OnInit, Input, Output, EventEmitter, ViewChildren, TemplateRef, ElementRef, QueryList, Inject, ChangeDetectorRef, ViewEncapsulation, OnChanges, SimpleChange } from '@angular/core';
import { TaskComponent } from '../task';
import * as _ from "lodash";
import { Observable, Subscription } from 'rxjs';
import { Howl } from "howler";

export declare type AudiogridBlock = {
  left: {
    audio: string;
    text: string;
  }[];
  right: {
    audio: string;
    text: string;
  }[];
}

export declare type AudiogridData = {
  type?: string;
  question?: string;
  blocks: AudiogridBlock[];
};

@Component({
  selector: "app-audiogrid",
  templateUrl: "./audiogrid.component.html",
  styleUrls: ["./audiogrid.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class AudiogridComponent implements TaskComponent, OnInit {
  @Input() data: AudiogridData;
  @Output() taskSubmitted: EventEmitter<boolean> = new EventEmitter();
  public isAnswered = false;
  @Input() submitTask: Observable<void>;
  public recordings: {left: Howl[], right: Howl[]}[] = [];

  constructor() {

  }

  ngOnInit() {
    this.recordings = this.data.blocks.map((block) => { return { left: block.left.map((item) => new Howl({src:[item.audio]})), right: block.right.map((item) => new Howl({src:[item.audio]}))}})
  }

  ngOnChanges() {
    this.recordings = this.data.blocks.map((block) => { return { left: block.left.map((item) => new Howl({src:[item.audio]})), right: block.right.map((item) => new Howl({src:[item.audio]}))}})
  }



}
