import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import * as _ from "lodash";
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from "@angular/cdk/drag-drop";
import { TaskComponent } from "../task";
import { Observable, Subscription } from "rxjs";

declare type Item = {
  title: string;
  image: string;
  isExample: boolean;
};

declare type Column = {
  title: string;
  items: Item[];
}

export declare type MatchingdragmulticolData = {
  type?: string;
  question: string;
  exampleInQuestion: boolean;
  showLabels: boolean;
  columns: Column[];
};

@Component({
  selector: "app-matchingdragmulticol",
  templateUrl: "./matchingdragmulticol.component.html",
  styleUrls: ["./matchingdragmulticol.component.css"]
})
export class MatchingdragmulticolComponent implements OnInit, TaskComponent {
  @Input() data: MatchingdragmulticolData;
  @Output() taskSubmitted: EventEmitter<boolean> = new EventEmitter();
  @Input() submitTask?: Observable<void>;
  private eventsSubscription: Subscription;
  public items: Item[][];
  public examples: Item[][];
  public unsortedItems: Item[];
  public isAnswered: boolean = false;

  constructor() {}

  ngOnInit() {
    this.eventsSubscription = this.submitTask.subscribe(() => this.submit());
    this.unsortedItems = _.shuffle(_.filter(_.flatten(_.map(this.data.columns, (column: Column) => column.items)), (item: Item) => !item.isExample));
    this.examples = []
    this.items = [];
    this.isAnswered = false;
    for(let i in this.data.columns) {
      this.items.push([]);
    }
    for(let i in this.data.columns) {
      this.examples[i] = _.filter(this.data.columns[i].items, (item: Item) => item.isExample);
    }
    console.log(this.items);
  }

  drop(event: CdkDragDrop<Item[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
  }

  submit() {
    let isCorrect = true;
    for(let i in this.data.columns) {
      for(let item of this.data.columns[i].items) {
        console.log(this.data.columns[i].items, this.items[i], this.examples[i])
        if(!this.items[i].includes(item) && !this.examples[i].includes(item)) {
          isCorrect = false;
        }
      }
    }
    this.taskSubmitted.next(isCorrect);
    this.isAnswered = true;
  }

  isCorrectAnswer(i, item) {
    return this.data.columns[i].items.includes(item);
  }

  missing(i) {
    return _.without(this.data.columns[i].items, ...this.items[i])
  }

  ngOnDestroy() {
    this.eventsSubscription.unsubscribe();
  }
}
