import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from "@angular/core";
import { compareStringAndArray } from "@shared/utilities/compare-strings";
import * as _ from "lodash";
import { Paragraph } from "src/app/dvz/dikobraz-3d-tiskne-a-modeluje/models/task.model";

declare type RandomwheelOption = {
  image: string;
  text: string;
};

export declare type RandomwheelData = {
  type?: string;
  question: Paragraph[];
  options: RandomwheelOption[];
};

@Component({
  selector: "app-randomwheel",
  templateUrl: "./randomwheel.component.html",
  styleUrls: ["./randomwheel.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class RandomwheelComponent implements OnInit {
  @Input() data: RandomwheelData;
  @Output() taskSubmitted: EventEmitter<boolean> = new EventEmitter();
  _ = _;
  public options: RandomwheelOption[] = [];
  public currentOption: RandomwheelOption;
  public currentIndex: number = -1;
  public isStarted: boolean = false;
  public isAnswered: boolean = false;

  constructor() {}

  ngOnInit() {
    this.options = _.shuffle(this.data.options);
  }

  next() {
    this.isStarted = true;
    this.currentIndex++;
    this.currentOption = undefined;
    setTimeout(() => {
      this.currentOption = this.options[this.currentIndex];
    }, 1000);
    if (!this.options[this.currentIndex + 1]) {
      this.isAnswered = true;
    }
  }

  submit() {
    this.taskSubmitted.emit(true);
  }
}
