import { DOCUMENT } from '@angular/common';
import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef, Inject, InjectionToken, ViewEncapsulation } from '@angular/core';
import { Paragraph } from 'src/app/dvz/dikobraz-3d-tiskne-a-modeluje/models/task.model';
import { TaskComponent } from '../task';
import * as _ from "lodash";

export declare type TablefillData = {
  type?: string;
  question: Paragraph[];
  rows: TableCell[][];
};

export declare type TableCell = TableCellImage | TableCellText | TableCellSelect | TableCellFill;

export interface TableCellText {
  type: string;
  text: string;
}

export interface TableCellImage {
  type: string;
  url: string;
}


export interface TableCellFill {
  type: string;
  correct: string;
  beforeText: string;
  afterText: string;
}


export interface TableCellSelect {
  type: string;
  options: string[];
  correct: string;
  beforeText: string;
  afterText: string;
}


@Component({
  selector: 'app-tablefill',
  templateUrl: './tablefill.component.html',
  styleUrls: ['./tablefill.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class TablefillComponent implements TaskComponent, OnInit {

  @Input() data: TablefillData;
  @Output() taskSubmitted: EventEmitter<boolean> = new EventEmitter();
  public answer: string;
  public isAnswered = false;
  public isCorrect: boolean[] = [];
  private document;

  constructor(@Inject(DOCUMENT) document, private changeRef: ChangeDetectorRef) { 
    this.document = document;
  }

  ngOnInit() {
  }

  getAnswer(type: string, row: number, col: number) {
    let id = type + "-" + row + "-" + col;
    if(!this.document.getElementById(id)) {
      return null;
    }
    this.document.getElementById(id).onchange = function(e) {
      if(this.changeRef) {
        this.changeRef.detectChanges();
      }
    }
    return this.document.getElementById(id).value;
  }

  isAllFilled() {
    let allFilled = true;
    if(!this.data) {
      return false;
    }
    for(let i in this.data.rows) {
      for(let j in this.data.rows[i]) {
        if(this.data.rows[i][j].type == "fill" || this.data.rows[i][j].type == "select") {
          if(!this.getAnswer(this.data.rows[i][j].type, parseInt(i), parseInt(j))) {
            allFilled = false;
          }
        }
      }
    }
    return allFilled;
  }

  submit() {
    this.isCorrect = [];
    for(let i in this.data.rows) {
      for(let j in this.data.rows[i]) {
        if(this.data.rows[i][j].type == "fill" || this.data.rows[i][j].type == "select") {
          let answer = this.data.rows[i][j]["correct"];
          let userAnswer = this.getAnswer(this.data.rows[i][j].type, parseInt(i), parseInt(j));
          this.isCorrect.push(userAnswer == answer);
          let inputEl = this.document.getElementById(this.data.rows[i][j].type + "-" + parseInt(i) + "-" + parseInt(j));
          const newEl = this.document.createElement('span');
          newEl.classList.add(userAnswer == answer ? 'correct' : 'incorrect', 'answer');
          newEl.innerHTML = userAnswer;
          inputEl.parentNode.replaceChild(newEl, inputEl);
          if(userAnswer != answer) {
            const correctAnswer = this.document.createElement('span');
            correctAnswer.classList.add('correct-answer');
            correctAnswer.innerHTML = '( správná odpověď: ' + answer + ')'
            newEl.parentNode.insertBefore(correctAnswer, newEl.nextSibling);
          }
        }
      }
    }
    if(_.every(this.isCorrect)) {
      this.taskSubmitted.emit(true);
    } else {
      this.taskSubmitted.emit(false);
    }
    this.isAnswered = true;
  }

}

