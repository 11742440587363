import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import * as _ from "lodash";
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from "@angular/cdk/drag-drop";
import { TaskComponent } from "../task";
import { Observable, Subscription } from "rxjs";

export declare type MatchingdraggridData = {
  type?: string;
  question: string;
  rows: string[][];
  prefilled: string[];
};

@Component({
  selector: "app-matchingdraggrid",
  templateUrl: "./matchingdraggrid.component.html",
  styleUrls: ["./matchingdraggrid.component.css"]
})
export class MatchingdraggridComponent implements OnInit, TaskComponent {
  @Input() data: MatchingdraggridData;
  @Output() taskSubmitted: EventEmitter<boolean> = new EventEmitter();
  @Input() submitTask?: Observable<void>;
  private eventsSubscription: Subscription;
  public items: string[][][];
  public unsortedItems: string[];
  public isAnswered: boolean = false;
  public prefilled: any[][] = []

  constructor() {}

  ngOnInit() {
    this.eventsSubscription = this.submitTask.subscribe(() => this.submit());
    if(this.data.prefilled && this.data.prefilled.length) {
      this.prefilled = this.data.prefilled.map((item) => item.split(","))
    }
    this.items = [];
    for(let i in this.data.rows) {
      this.items.push([]);
      for(let j in this.data.rows[i]) {
        this.items[i].push([]);
      }
    }
    
    let rowsCopy = _.cloneDeep(this.data.rows);
    if(this.data.prefilled && this.data.prefilled.length) {
      for(let mark of this.prefilled) {
        rowsCopy[parseInt(mark[0])][parseInt(mark[1])] = null;
        this.items[parseInt(mark[0])][parseInt(mark[1])]= [this.data.rows[parseInt(mark[0])][parseInt(mark[1])]];
      }
    }
    this.unsortedItems = _.filter(_.shuffle(_.flatten(_.flatten(rowsCopy))), el => !_.isNull(el));
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
  }

  submit() {
    let isCorrect = true;
    this.isAnswered = true;
    for(let row of this.items) {
      let columnOf = this.firstColumnOf(row[0][0]);
      if(isCorrect) {
        if(columnOf == -1) {
          isCorrect = false;
        } else {
          for(let j in this.data.rows[columnOf]) {
            if(row[j][0] != this.data.rows[columnOf][j]) {
              isCorrect = false;
            }
          }
        }
      }
    }
    this.taskSubmitted.next(isCorrect);
  }

  isNthColumn(item: string, row: number, n: number) {
    return this.data.rows[row][n] == item;
  }

  firstColumnOf(item: string) {
    let firstOf = -1;
    for(let i in this.data.rows) {
      if(this.data.rows[i][0] == item) {
        firstOf = parseInt(i)
      }
    }
    return firstOf;
  }

  itemCorrect(row: number, col: number) {
    let columnOf = this.firstColumnOf(this.items[row][0][0]);
    if(columnOf == -1) {
      return false;
    }
    if(col == 0) {
      return true
    }
    return this.isNthColumn(this.items[row][col][0],columnOf, col);
  }

  ngOnDestroy() {
    this.eventsSubscription.unsubscribe();
  }
}
