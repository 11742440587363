import { Component, OnInit } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { Howler } from 'howler';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  loading = true;

  constructor(router: Router) {
    // decide what to do when this event is triggered.
    router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(val => {
      console.log(val);
      Howler.stop();
      this.loading = false;
    });

    router.events.pipe(filter(event => event instanceof NavigationCancel)).subscribe(val => {
      this.loading = false;
    });

    router.events.pipe(filter(event => event instanceof NavigationError)).subscribe(val => {
      this.loading = false;
    });

    router.events.pipe(filter(event => event instanceof NavigationStart)).subscribe(val => {
      this.loading = true;
    });
  }


  private observer: MutationObserver = null;

  ngOnDestroy() {
    this.observer.disconnect();
  }

  public ngOnInit(): void {
  }

  ngAfterViewInit() {
    const node = document.querySelector('body');
    this.observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (
          mutation.addedNodes.length > 0 &&
          mutation.addedNodes.length > 0 &&
          mutation.addedNodes.item(0)['classList'] == 'cdk-overlay-container'
        ) {
          this.observer.disconnect();
          this.observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
              if (mutation.addedNodes.length > 0) {
                let tooltip = mutation.addedNodes[0];
                while (
                  tooltip.hasChildNodes() &&
                  tooltip.firstChild.nodeType == Node.ELEMENT_NODE
                ) {
                  tooltip = tooltip.childNodes[0];
                }
                if (tooltip) {
                  tooltip['innerHTML'] = tooltip['innerHTML']
                    .replaceAll('&lt;', '<')
                    .replaceAll('&gt;', '>');
                }
              }
            });
          });
          this.observer.observe(mutation.addedNodes.item(0), {
            attributes: false,
            childList: true,
            characterData: false
          });
        }
      });
    });

    this.observer.observe(node, {
      attributes: false,
      childList: true,
      characterData: false
    });
  }

}
