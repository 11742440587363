import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Paragraph } from "src/app/dvz/dikobraz-se-uci-anglicky/models/task.model";
import { TaskComponent } from "../task";

export declare type OpenquestionData = {
  type?: string;
  question: Paragraph[];
  answer: Paragraph[];
};

@Component({
  selector: "app-openquestion",
  templateUrl: "./openquestion.component.html",
  styleUrls: ["./openquestion.component.css"],
})
export class OpenquestionComponent implements TaskComponent, OnInit {
  @Input() data: OpenquestionData;
  @Output() taskSubmitted: EventEmitter<boolean> = new EventEmitter();
  public answered = false;

  constructor() {}

  ngOnInit() {}

  answer() {
    this.answered = true;
  }
  
  next() {
    this.taskSubmitted.emit(true);
  }
}
