export const mainEntryPoints: EntryPoint[] = [
    {
      id: 0,
      name: "kvk",
      style: {
        left: "23%",
        top: "25%",
        height: "2.5rem",
        width: "2.5rem",
        "background-color": "red",
      },
    },
    {
      id: 1,
      name: "plk",
      style: {
        left: "24%",
        top: "45%",
        height: "4rem",
        width: "4rem",
        "background-color": "red",
      },
    },
    {
      id: 2,
      name: "ulk",
      style: {
        left: "33%",
        top: "15%",
        height: "3rem",
        width: "3rem",
        "background-color": "red",
      },
    },
    {
      id: 3,
      name: "stc",
      style: {
        left: "39%",
        top: "45%",
        height: "3rem",
        width: "3rem",
        "background-color": "red",
      },
    },
    {
      id: 4,
      name: "pha",
      style: {
        left: "39.5%",
        top: "35.5%",
        height: "1.5rem",
        width: "1.5rem",
        "background-color": "red",
      },
    },
    {
      id: 5,
      name: "jhc",
      style: {
        left: "35%",
        top: "65%",
        height: "5rem",
        width: "5rem",
        "background-color": "red",
      },
    },
    {
      id: 6,
      name: "lbk",
      style: {
        left: "46%",
        top: "12%",
        height: "3rem",
        width: "3rem",
        "background-color": "red",
      },
    },
    {
      id: 7,
      name: "hkk",
      style: {
        left: "53%",
        top: "27%",
        height: "3.5rem",
        width: "3.5rem",
        "background-color": "red",
      },
    },
    {
      id: 8,
      name: "pak",
      style: {
        left: "56%",
        top: "47%",
        height: "3.5rem",
        width: "3.5rem",
        "background-color": "red",
      },
    },
    {
      id: 9,
      name: "vys",
      style: {
        left: "49%",
        top: "60%",
        height: "4rem",
        width: "4rem",
        "background-color": "red",
      },
    },
    {
      id: 10,
      name: "jhm",
      style: {
        left: "58%",
        top: "75%",
        height: "4rem",
        width: "4rem",
        "background-color": "red",
      },
    },
    {
      id: 11,
      name: "olk",
      style: {
        left: "64%",
        top: "56%",
        height: "3rem",
        width: "3rem",
        "background-color": "red",
      },
    },
    {
      id: 12,
      name: "zlk",
      style: {
        left: "69%",
        top: "76%",
        height: "3rem",
        width: "3rem",
        "background-color": "red",
      },
    },
    {
      id: 13,
      name: "msk",
      style: {
        left: "74%",
        top: "55%",
        height: "4rem",
        width: "4rem",
        "background-color": "red",
      },
    },
  ];
  
  export class EntryPoint {
    id: number;
    name: string;
    style: {
      left: string;
      top: string;
      height: string;
      width: string;
      "background-color": string;
    };
  }
  