import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  Inject,
  ChangeDetectorRef,
  ViewEncapsulation,
} from "@angular/core";
import { TaskComponent } from "../task";
import { DOCUMENT } from "@angular/common";
import * as _ from "lodash";
import { matchAll } from "@shared/utilities";
import {
  spacesToUnderlines,
  underlinesToSpaces,
} from "@shared/utilities/compare-strings";
import { Observable, Subscription } from "rxjs";

export declare type MultiselectData = {
  type?: string;
  question: string;
  rows: string[];
};

@Component({
  selector: "app-multiselect",
  templateUrl: "./multiselect.component.html",
  styleUrls: ["./multiselect.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class MultiselectComponent implements TaskComponent, OnInit {
  @Input() data: MultiselectData;
  @Output() taskSubmitted: EventEmitter<boolean> = new EventEmitter();
  private document;
  public isAnswered = false;
  public isCorrect: boolean[] = [];
  public filledTemplate = "";
  public questions: {
    correct: string;
    options: string[];
    id: string;
  }[] = [];
  @Input() submitTask: Observable<void>;
  private eventsSubscription: Subscription;

  constructor(
    @Inject(DOCUMENT) document,
    private changeRef: ChangeDetectorRef
  ) {
    this.document = document;
  }

  ngOnInit() {
    this.eventsSubscription = this.submitTask.subscribe(() => this.submit());
    this.createGaps();
  }

  ngOnDestroy() {
    this.eventsSubscription.unsubscribe();
  }

  submit() {
    this.isCorrect = [];
    for (let answerIndex in this.questions) {
      let answer = this.questions[answerIndex];
      let userAnswer = this.getAnswer(parseInt(answerIndex));
      this.isCorrect.push(userAnswer == answer.correct);
      let inputEl = this.document.getElementById(
        this.questions[answerIndex].id
      );
      const newEl = this.document.createElement("span");
      newEl.classList.add(
        userAnswer == answer.correct ? "correct" : "incorrect",
        "answer"
      );
      newEl.innerHTML = userAnswer;
      inputEl.parentNode.replaceChild(newEl, inputEl);
      if (userAnswer != answer.correct) {
        const correctAnswer = this.document.createElement("span");
        correctAnswer.classList.add("correct-answer");
        correctAnswer.innerHTML = "( správná odpověď: " + answer.correct + ")";
        newEl.parentNode.insertBefore(correctAnswer, newEl.nextSibling);
      }
    }
    if (_.every(this.isCorrect)) {
      this.taskSubmitted.emit(true);
    } else {
      this.taskSubmitted.emit(false);
    }
    this.isAnswered = true;
  }

  getAnswer(id: number) {
    if (!this.document.getElementById(this.questions[id].id)) {
      return null;
    }
    this.document.getElementById(this.questions[id].id).onchange = (e) => {
      if (this.changeRef) {
        this.changeRef.detectChanges();
      }
    };
    return underlinesToSpaces(
      this.document.getElementById(this.questions[id].id).value
    );
  }

  isAllFilled() {
    let allFilled = true;
    if (!this.data) {
      return false;
    }
    for (let answerIndex in this.questions) {
      if (!this.getAnswer(parseInt(answerIndex))) {
        allFilled = false;
      }
    }
    return allFilled;
  }

  createGaps() {
    let template: string = this.data.rows.join("<br>");

    this.questions = [];
    let gapMatches = matchAll(/\{#SELECT:([^}*]+)\}/g, template);
    for (let i in gapMatches) {
      let questionTemplate = gapMatches[i][0]
        .replace("{#SELECT:", "")
        .replace("}", "");
      let correct = questionTemplate.split(":")[0];
      let options = _.shuffle(questionTemplate.split(":")[1].split(","));
      this.questions.push({
        correct: correct,
        options: options,
        id: "select-" + i,
      });
    }
    let gappedText = template;
    for (let i in this.questions) {
      gappedText = gappedText.replace(
        gapMatches[i][0],
        '<select class="multiselect" id="' +
          this.questions[i].id +
          '">' +
          this.questions[i].options.map(
            (value) =>
              "<option value=" +
              spacesToUnderlines(value) +
              ">" +
              value +
              "</option>"
          ) +
          "</select>"
      );
    }
    this.filledTemplate = gappedText;
  }
}
