import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { TaskDirective } from "./task.directive";
import { MultichoiceComponent } from "./multichoice/multichoice.component";
import { SinglechoiceComponent } from "./singlechoice/singlechoice.component";
import {
  MatRadioModule,
  MatButtonModule,
  MatInputModule,
  MatCheckboxModule,
  MatSelectModule,
  MatCardModule,
  MatGridListModule,
} from "@angular/material";
import { MultifillComponent } from "./multifill/multifill.component";
import { SanitizePipe } from "@shared/pipes/sanitize-pipe.pipe";
import { MultidragComponent } from "./multidrag/multidrag.component";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { OrderdragComponent } from "./orderdrag/orderdrag.component";
import { InsertorderdragComponent } from "./insertorderdrag/insertorderdrag.component";
import { MiscModule } from "../components/misc/misc.module";
import { SelectComponent } from "./select/select.component";
import { CalculateComponent } from "./calculate/calculate.component";
import { MultiselectComponent } from "./multiselect/multiselect.component";
import { MatchingdragComponent } from "./matchingdrag/matchingdrag.component";
import { TablefillComponent } from "./tablefill/tablefill.component";
import { PexesoComponent } from "./pexeso/pexeso.component";
import { InsertanswerComponent } from "./insertanswer/insertanswer.component";
import { MatchingdragcolComponent } from "./matchingdragcol/matchingdragcol.component";
import { MultiinsertComponent } from "./multiinsert/multiinsert.component";
import { MarkAndReplaceComponent } from "./markandreplace/markandreplace.component";
import { TabledragComponent } from "./tabledrag/tabledrag.component";
import { PexesoFillComponent } from "./pexesofill/pexesofill.component";
import { OrdinalNumbersComponent } from "./ordinalnumbers/ordinalnumbers.component";
import { FreecontentComponent } from "./freecontent/freecontent.component";
import { MatchingdragmulticolComponent } from "./matchingdragmulticol/matchingdragmulticol.component";
import { TruefalseComponent } from "./truefalse/truefalsecomponent";
import { CrosswordComponent } from "./crossword/crossword.component";
import { FrKeyboardComponent } from "./fr-keyboard/fr-keyboard.component";
import { NewsinglechoiceComponent } from "./newsinglechoice/newsinglechoice.component";
import { FlyingwordsComponent } from "./flyingwords/flyingwords.component";
import { SortingwordsComponent } from "./sortingwords/sortingwords.component";
import { RandomwheelComponent } from "./randomwheel/randomwheel.component";
import { PicturemultiinsertComponent } from "./picturemultiinsert/picturemultiinsert.component";
import { MatchingdragpicturesComponent } from "./matchingdragpictures/matchingdragpictures.component";
import { SortingverbsComponent } from "./sortingverbs/sortingverbs.component";
import { MatchingdraginsideComponent } from "./matchingdraginside/matchingdraginside.component";
import { SingleAudioTextComponent } from "./singleaudiotext/singleaudiotext.component";
import { SingleAudioImageComponent } from "./singleaudioimage/singleaudioimage.component";
import { MultimultifillComponent } from "./multimultifill/multimultifill.component";
import { HangmanComponent } from "./hangman/hangman.component";
import { PexesoAudioComponent } from "./pexesoaudio/pexesoaudio.component";
import { UncoverComponent } from "./uncover/uncover.component";
import { OsmismerkaComponent } from "./osmismerka/osmismerka.component";
import { OpenquestionComponent } from "./openquestion/openquestion.component";
import { ComicComponent } from "./comics/comics.component";
import { ColumnPexesoComponent } from "./columnpexeso/columnpexeso.component";
import { MultilinefillComponent } from "./multilinefill/multilinefill.component";
import { MultilinegapchoiceComponent } from "./multilinegapchoice/multilinegapchoice.component";
import { MultilinesoundfillComponent } from "./multilinesoundfill/multilinesoundfill.component";
import { MultilineaudiochoiceComponent } from "./multilineaudiochoice/multilineaudiochoice.component";
import { MultilinegapselectComponent } from "./multilinegapselect/multilinegapselect.component";
import { AudiogridComponent } from "./audiogrid/audiogrid.component";
import { MatchingdragimageComponent } from "./matchingdragimage/matchingdragimage.component";
import { FillingimageComponent } from "./fillingimage/fillingimage.component";
import { MultilinegapfillComponent } from "./multilinegapfill/multilinegapfill.component";
import { MatchingdragmulticoldupComponent } from "./matchingdragmulticoldup/matchingdragmulticoldup.component";
import { MatchingdraggridComponent } from "./matchingdraggrid/matchingdraggrid.component";
import { OrderdraginlineComponent } from "./orderdraginline/orderdraginline.component";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatRadioModule,
    MatButtonModule,
    MatInputModule,
    MatCheckboxModule,
    MatSelectModule,
    DragDropModule,
    MiscModule,
    MatCardModule,
    MatGridListModule
  ],
  declarations: [
    TaskDirective,
    MultichoiceComponent,
    SinglechoiceComponent,
    MultifillComponent,
    SanitizePipe,
    MultidragComponent,
    OrderdragComponent,
    InsertorderdragComponent,
    InsertanswerComponent,
    SelectComponent,
    CalculateComponent,
    MultiselectComponent,
    MultiinsertComponent,
    MatchingdragComponent,
    MatchingdragcolComponent,
    TablefillComponent,
    PexesoComponent,
    MarkAndReplaceComponent,
    TabledragComponent,
    PexesoFillComponent,
    OrdinalNumbersComponent,
    FreecontentComponent,
    MatchingdragmulticolComponent,
    TruefalseComponent,
    CrosswordComponent,
    FrKeyboardComponent,
    NewsinglechoiceComponent,
    FlyingwordsComponent,
    SortingwordsComponent,
    RandomwheelComponent,
    PicturemultiinsertComponent,
    MatchingdragpicturesComponent,
    SortingverbsComponent,
    MatchingdraginsideComponent,
    SingleAudioTextComponent,
    SingleAudioImageComponent,
    MultimultifillComponent,
    HangmanComponent,
    PexesoAudioComponent,
    UncoverComponent,
    OsmismerkaComponent,
    OpenquestionComponent,
    ComicComponent,
    ColumnPexesoComponent,
    MultilinefillComponent,
    MultilinegapchoiceComponent,
    MultilinesoundfillComponent,
    MultilineaudiochoiceComponent,
    MultilinegapselectComponent,
    AudiogridComponent,
    MatchingdragimageComponent,
    FillingimageComponent,
    MultilinegapfillComponent,
    MatchingdragmulticoldupComponent,
    MatchingdraggridComponent,
    OrderdraginlineComponent
  ],
  exports: [
    TaskDirective,
    MultichoiceComponent,
    SinglechoiceComponent,
    MultifillComponent,
    SanitizePipe,
    MultidragComponent,
    OrderdragComponent,
    InsertorderdragComponent,
    InsertanswerComponent,
    SelectComponent,
    CalculateComponent,
    MultiselectComponent,
    MultiinsertComponent,
    MatchingdragComponent,
    MatchingdragcolComponent,
    TablefillComponent,
    PexesoComponent,
    MarkAndReplaceComponent,
    TabledragComponent,
    PexesoFillComponent,
    OrdinalNumbersComponent,
    FreecontentComponent,
    MatchingdragmulticolComponent,
    CrosswordComponent,
    TruefalseComponent,
    FrKeyboardComponent,
    NewsinglechoiceComponent,
    FlyingwordsComponent,
    SortingwordsComponent,
    RandomwheelComponent,
    PicturemultiinsertComponent,
    MatchingdragpicturesComponent,
    SortingverbsComponent,
    MatchingdraginsideComponent,
    SingleAudioTextComponent,
    SingleAudioImageComponent,
    MultimultifillComponent,
    HangmanComponent,
    PexesoAudioComponent,
    UncoverComponent,
    OsmismerkaComponent,
    OpenquestionComponent,
    ComicComponent,
    ColumnPexesoComponent,
    MultilinefillComponent,
    MultilinegapchoiceComponent,
    MultilinesoundfillComponent,
    MultilineaudiochoiceComponent,
    MultilinegapselectComponent,
    AudiogridComponent,
    MatchingdragimageComponent,
    FillingimageComponent,
    MultilinegapfillComponent,
    MatchingdragmulticoldupComponent,
    MatchingdraggridComponent,
    OrderdraginlineComponent
  ],
  entryComponents: [
    MultichoiceComponent,
    SinglechoiceComponent,
    MultifillComponent,
    MultidragComponent,
    OrderdragComponent,
    InsertorderdragComponent,
    InsertanswerComponent,
    SelectComponent,
    CalculateComponent,
    MultiselectComponent,
    MultiinsertComponent,
    MatchingdragComponent,
    MatchingdragcolComponent,
    TablefillComponent,
    PexesoComponent,
    MarkAndReplaceComponent,
    TabledragComponent,
    PexesoFillComponent,
    OrdinalNumbersComponent,
    FreecontentComponent,
    MatchingdragmulticolComponent,
    CrosswordComponent,
    TruefalseComponent,
    FrKeyboardComponent,
    NewsinglechoiceComponent,
    FlyingwordsComponent,
    SortingwordsComponent,
    RandomwheelComponent,
    PicturemultiinsertComponent,
    MatchingdragpicturesComponent,
    SortingverbsComponent,
    MatchingdraginsideComponent,
    SingleAudioTextComponent,
    SingleAudioImageComponent,
    MultimultifillComponent,
    HangmanComponent,
    PexesoAudioComponent,
    UncoverComponent,
    OsmismerkaComponent,
    OpenquestionComponent,
    ComicComponent,
    ColumnPexesoComponent,
    MultilinefillComponent,
    MultilinegapchoiceComponent,
    MultilinesoundfillComponent,
    MultilineaudiochoiceComponent,
    MultilinegapselectComponent,
    AudiogridComponent,
    MatchingdragimageComponent,
    FillingimageComponent,
    MultilinegapfillComponent,
    MatchingdragmulticoldupComponent,
    MatchingdraggridComponent,
    OrderdraginlineComponent
  ],
})
export class TasksModule {}
