import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { TaskComponent } from "../task";
import * as _ from "lodash";
import { Howl } from 'howler';

declare type Question = {
  pairId: number;
  isPicture: boolean;
  audio?: Howl;
  content: string;
  isSelected: boolean;
  isAnswered: boolean;
};

export declare type PexesoAudioData = {
  type?: string;
  question: string;
  questions: {
    text: string;
    imageUrl: string;
    audioUrl?: string;
  }[];
};

@Component({
  selector: "app-pexesoaudio",
  templateUrl: "./pexesoaudio.component.html",
  styleUrls: ["./pexesoaudio.component.css"],
})
export class PexesoAudioComponent implements TaskComponent, OnInit {
  @Input() data: PexesoAudioData;
  @Output() taskSubmitted: EventEmitter<boolean> = new EventEmitter();
  public pairs: Question[][] = [];
  public isAnswering: boolean = false;
  public isAnswered: boolean = false;
  public answeredQuestions: Question[] = [];
  public questions: Question[] = [];
  public cols: number;

  constructor() {}

  ngOnInit() {
    let i = 0;
    this.data.questions.forEach((question) => {
      this.questions.push({
        pairId: i,
        isPicture: true,
        content: question.imageUrl,
        audio: question.audioUrl ? new Howl({
          src: [  question.audioUrl ]
        }) : null,
        isSelected: false,
        isAnswered: false,
      });
      this.questions.push({
        pairId: i,
        isPicture: false,
        content: question.text,
        isSelected: false,
        isAnswered: false,
      });
      i++;
    });
    this.questions = _.shuffle(this.questions);
    this.cols = this.data.questions.length === 6 ? 3 : this.data.questions.length === 8 ? 4 : this.data.questions.length;
    if(this.cols > 4) {
      this.cols = 4;
    }
    console.log(this.cols)
    this.taskSubmitted.emit(true);
  }

  flip(question: Question) {
    if (this.answeredQuestions.length === 2) {
      this.closeQuestions();
    }
    if(question.isAnswered || question.isSelected) {
      return;
    }
    question.isSelected = true;
    this.answeredQuestions.push(question);
    if (this.answeredQuestions.length === 2) {
      this.checkAnswer();
    }
  }

  checkAnswer() {
    if (this.answeredQuestions[0].pairId === this.answeredQuestions[1].pairId) {
      this.answeredQuestions.forEach(
        (question) => (question.isAnswered = true)
      );
    }
  }

  closeQuestions() {
    if (_.every(this.questions, (question) => question == null)) {
      this.taskSubmitted.emit(true);
      this.isAnswered = true;
    }
    this.answeredQuestions.forEach((question) => (question.isSelected = false));
    this.answeredQuestions = [];
  }
}
