import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from "@angular/core";
import * as _ from "lodash";
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from "@angular/cdk/drag-drop";
import { TaskComponent } from "../task";

declare type Verb = {
  firstForm: string;
  secondForm: string;
  thirdForm: string;
};

export declare type SortingverbsData = {
  type?: string;
  question: string;
  verbs: Verb[];
};

@Component({
  selector: "app-sortingverbs",
  templateUrl: "./sortingverbs.component.html",
  styleUrls: ["./sortingverbs.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class SortingverbsComponent implements TaskComponent {
  @Input() data: SortingverbsData;
  @Output() taskSubmitted: EventEmitter<boolean> = new EventEmitter();
  public dragIns: string[][][] = [];
  public isAnswered: boolean = false;
  public shuffledOptions: string[] = [];
  public options: string[] = [];
  public selectedVerbs: Verb[] = [];
  public isCorrect = false;

  constructor() {}

  ngOnInit() {
    this.options = [];
    const verbs: Verb[] = _.shuffle(this.data.verbs);
    for (let i = 0; i < 10; i++) {
      if (verbs[i]) {
        this.selectedVerbs.push(verbs[i]);
        this.dragIns[i] = [[], [], []];
        this.options.push(
          verbs[i].firstForm,
          verbs[i].secondForm,
          verbs[i].thirdForm
        );
      }
    }
    this.shuffledOptions = _.shuffle(this.options);
  }

  drop(event: CdkDragDrop<string[]>) {
    if (!this.isAnswered) {
      if (
        event.container.id === "options" ||
        event.previousContainer.id === "options"
      ) {
        if (event.previousContainer === event.container) {
          moveItemInArray(
            event.container.data,
            event.previousIndex,
            event.currentIndex
          );
        } else {
          if (event.container.data.length == 0) {
            transferArrayItem(
              event.previousContainer.data,
              event.container.data,
              event.previousIndex,
              event.currentIndex
            );
          }
        }
      } else {
        if (event.container.data.length !== 0) {
          transferArrayItem(
            event.previousContainer.data,
            event.container.data,
            0,
            0
          );
          transferArrayItem(
            event.container.data,
            event.previousContainer.data,
            1,
            0
          );
        } else {
          transferArrayItem(
            event.previousContainer.data,
            event.container.data,
            event.previousIndex,
            event.currentIndex
          );
        }
      }
    }
  }

  setColor(word, i, j) {
    if (!this.isAnswered) {
      return "";
    }
    if (word === this.selectedVerbs[i][this.mapToProp(j)]) {
      return "correct";
    } else {
      return "incorrect";
    }
  }

  mapToProp(i: number): string {
    switch (i) {
      case 0:
        return "firstForm";
      case 1:
        return "secondForm";
      case 2:
        return "thirdForm";
    }
  }

  submit() {
    const isCorrect: boolean[] = [];
    for (let i in this.selectedVerbs) {
      for (let j in this.dragIns[i]) {
        const correct =
          this.selectedVerbs[i][this.mapToProp(Number(j))] ===
          this.dragIns[i][j][0];
        isCorrect.push(correct);
      }
    }
    this.isCorrect = _.every(isCorrect);
    if (this.isCorrect) {
      this.taskSubmitted.emit(true);
    } else {
      this.taskSubmitted.emit(false);
    }
    this.isAnswered = true;
  }
}
