import { Component, OnInit, Input, Output, EventEmitter, ViewChildren, TemplateRef, ElementRef, QueryList, Inject, ChangeDetectorRef, ViewEncapsulation, OnChanges, SimpleChange } from '@angular/core';
import { TaskComponent } from '../task';
import * as _ from "lodash";
import { Observable, Subscription } from 'rxjs';

export declare type MultilinefillLine = {
  textBefore?: string;
  textAfter?: string;
  correct: string;
  isExample: boolean;
}

export declare type MultilinefillData = {
  type?: string;
  question?: string;
  exampleInQuestion: boolean;
  lines: MultilinefillLine[];
};

@Component({
  selector: "app-multilinefill",
  templateUrl: "./multilinefill.component.html",
  styleUrls: ["./multilinefill.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class MultilinefillComponent implements TaskComponent, OnInit {
  @Input() data: MultilinefillData;
  @Output() taskSubmitted: EventEmitter<boolean> = new EventEmitter();
  public isAnswered = false;
  @Input() submitTask: Observable<void>;
  private eventsSubscription: Subscription;
  public isCorrect = false;
  public answers: string[] = [];
  public lines: MultilinefillLine[] = [];
  public examples: MultilinefillLine[] = [];

  constructor() {

  }

  ngOnInit() {
    this.eventsSubscription = this.submitTask.subscribe(() => this.submit());
    this.lines = this.data.lines.filter((line) => !line.isExample);
    this.examples = this.data.lines.filter((line) => line.isExample);
    this.answers = Array(this.lines.length).fill("");
  }

  ngOnChanges() {
    this.lines = this.data.lines.filter((line) => !line.isExample);
    this.examples = this.data.lines.filter((line) => line.isExample);
    this.answers = Array(this.lines.length).fill("");
  }

  ngOnDestroy() {
    this.eventsSubscription.unsubscribe();
  }


  submit() {
    console.log("SUBMITTED")
    this.isCorrect = true;
    for (let answerIndex in this.answers) {
      if(this.answers[answerIndex] != this.lines[answerIndex].correct) {
        this.isCorrect = false;
      }
    }
    this.taskSubmitted.emit(this.isCorrect);
    this.isAnswered = true;
  }
}
