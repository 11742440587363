import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Observable, Subscription } from "rxjs";
import { TaskComponent } from "../task";
import * as _ from "lodash";

export declare type SinglechoiceData = {
  type?: string;
  question: string;
  options: string[];
  images: string[];
  correct: string;
};

@Component({
  selector: "app-singlechoice",
  templateUrl: "./singlechoice.component.html",
  styleUrls: ["./singlechoice.component.css"],
})
export class SinglechoiceComponent implements TaskComponent, OnInit {
  @Input() data: SinglechoiceData;
  @Input() submitTask: Observable<void>;
  private eventsSubscription: Subscription;
  @Output() taskSubmitted: EventEmitter<boolean> = new EventEmitter();
  public answer: string;
  public isAnswered: boolean = false;
  public options: string[];

  constructor() {}

  ngOnInit() {
    this.eventsSubscription = this.submitTask.subscribe(() => this.submit())
    this.options = _.shuffle(this.data.options)
  }

  getImage(option: string) {
    if(!this.data.images || this.data.images.length == 0) {
      return null;
    }
    return this.data.images[this.data.options.indexOf(option)];
  }

  submit() {
    if (this.answer == this.data.correct) {
      this.taskSubmitted.emit(true);
    } else {
      this.taskSubmitted.emit(false);
    }
    this.isAnswered = true;
  }

  ngOnDestroy() {
    this.eventsSubscription.unsubscribe();
  }

}
