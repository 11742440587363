import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from "@angular/core";
import { compareStringAndArray } from "@shared/utilities/compare-strings";
import * as _ from "lodash";
import { Paragraph } from "src/app/dvz/dikobraz-3d-tiskne-a-modeluje/models/task.model";

declare type InsertanswerOption = {
  text: Paragraph;
  correct: string[];
};

export declare type InsertanswerData = {
  type?: string;
  question: Paragraph[];
  isShuffled: boolean;
  isFrKey?: boolean;
  options: InsertanswerOption[];
};

declare type InsertResults = {
  [key: number]: string;
};

@Component({
  selector: "app-insertanswer",
  templateUrl: "./insertanswer.component.html",
  styleUrls: ["./insertanswer.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class InsertanswerComponent implements OnInit {
  @Input() data: InsertanswerData;
  @Output() taskSubmitted: EventEmitter<boolean> = new EventEmitter();
  _ = _;
  public options: InsertanswerOption[] = [];
  public insertResults: InsertResults = {};
  public isAnswered: boolean = false;
  private isCorrect: boolean[] = [];
  public focusTarget: { target: any; index: number };

  constructor() {}

  ngOnInit() {
    console.log(this.data);
    this.options = this.data.isShuffled
      ? _.shuffle(this.data.options)
      : this.data.options;
  }

  onKey(event: any, i: number) {
    this.insertResults[i] = event.target.value;
  }

  onClick($event: { value: string }) {
    this.focusTarget.target.value += $event.value;
    this.onKey(this.focusTarget, this.focusTarget.index);
  }

  onFocusEvent(event: any, i: number) {
    this.focusTarget = { target: event.target, index: i };
  }

  setColor(i: number) {
    if (!this.isAnswered) {
      return "";
    }
    if (
      compareStringAndArray(this.insertResults[i], this.options[i].correct) &&
      this.insertResults[i]
    ) {
      return "correct";
    } else {
      return "incorrect";
    }
  }

  submit() {
    this.isCorrect = [];
    let correctAns;
    let i = 0;
    for (let option in this.options) {
      correctAns =
        compareStringAndArray(this.insertResults[i], this.options[i].correct) &&
        this.insertResults[i];
      this.isCorrect.push(correctAns);
      i++;
    }
    if (_.every(this.isCorrect)) {
      this.taskSubmitted.emit(true);
    } else {
      this.taskSubmitted.emit(false);
    }
    this.isAnswered = true;
  }
}
