export const compareStrings = (textA: string, textB: string): boolean => {
  if (typeof textA === "string" && typeof textB === "string") {
    return normalize(textA) === normalize(textB);
  } else {
    return false;
  }
};

export const normalize = (text: string): string => {
  return text
    .normalize("NFD")
    .replace(/\p{Diacritic}/gu, "")
    .trim()
    .toLowerCase();
};

export const isStringInArr = (text: string, arr: string[]): boolean => {
  for (const element of arr) {
    if (compareStrings(text, element)) {
      return true;
    }
  }
  return false;
};

export const compareStringAndArray = (
  text: string,
  array: string[]
): boolean => {
  return array.some((string) => compareStrings(text, string));
};

export const spacesToUnderlines = (text: string): string => {
  return text.replace(/ /gu, "_");
};

export const underlinesToSpaces = (text: string): string => {
  return text.replace(/_/gu, " ");
};
