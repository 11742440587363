import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import * as _ from "lodash";
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
  copyArrayItem
} from "@angular/cdk/drag-drop";
import { TaskComponent } from "../task";
import { Observable, Subscription } from "rxjs";

declare type Item = {
  title: string;
  image: string;
};

declare type Column = {
  title: string;
  items: Item[];
}

export declare type MatchingdragmulticoldupData = {
  type?: string;
  question: string;
  showLabels: boolean;
  columns: Column[];
};

@Component({
  selector: "app-matchingdragmulticoldup",
  templateUrl: "./matchingdragmulticoldup.component.html",
  styleUrls: ["./matchingdragmulticoldup.component.css"]
})
export class MatchingdragmulticoldupComponent implements OnInit, TaskComponent {
  @Input() data: MatchingdragmulticoldupData;
  @Output() taskSubmitted: EventEmitter<boolean> = new EventEmitter();
  @Input() submitTask?: Observable<void>;
  private eventsSubscription: Subscription;
  public items: Item[][];
  public unsortedItems: Item[];
  public isAnswered: boolean = false;

  constructor() {}

  ngOnInit() {
    this.eventsSubscription = this.submitTask.subscribe(() => this.submit());
    this.unsortedItems = _.uniqBy(_.shuffle(_.flatten(_.map(this.data.columns, (column: Column) => column.items))), "title");
    console.log(this.unsortedItems);
    this.items = [];
    for(let i in this.data.columns) {
      this.items.push([]);
    }
    console.log(this.items);
  }

  drop(event: CdkDragDrop<Item[]>, i) {
    console.log(event.container.id, event.previousContainer.id, i)
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else if (event.container.id == "unsorted-column") {
      transferArrayItem(
        event.previousContainer.data,
        [],
        event.previousIndex,
        event.currentIndex
      );
    }
    else if(event.previousContainer.id == "unsorted-column"){
      copyArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
    else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
  }

  submit() {
    let isCorrect = true;
    this.isAnswered = true;
    for(let i in this.data.columns) {
      for(let item of this.data.columns[i].items) {
        console.log(this.data.columns[i].items, this.items[i])
        if(!this.items[i].includes(item)) {
          isCorrect = false;
        }
      }
    }
    this.taskSubmitted.next(isCorrect);
  }

  hasSameItem(col: Item[], item: Item) {
    return col.some(it => it.title == item.title && it.image == item.image);
  }
  ngOnDestroy() {
    this.eventsSubscription.unsubscribe();
  }
}
