import { MultichoiceComponent } from "./multichoice/multichoice.component";
import { SinglechoiceComponent } from "./singlechoice/singlechoice.component";
import { MultifillComponent } from "./multifill/multifill.component";
import { MultidragComponent } from "./multidrag/multidrag.component";
import { OrderdragComponent } from "./orderdrag/orderdrag.component";
import { InsertorderdragComponent } from "./insertorderdrag/insertorderdrag.component";
import { SelectComponent } from "./select/select.component";
import { CalculateComponent } from "./calculate/calculate.component";
import { MultiselectComponent } from "./multiselect/multiselect.component";
import { MatchingdragComponent } from "./matchingdrag/matchingdrag.component";
import { TablefillComponent } from "./tablefill/tablefill.component";

import { InsertanswerComponent } from "./insertanswer/insertanswer.component";
import { MatchingdragcolComponent } from "./matchingdragcol/matchingdragcol.component";
import { MultiinsertComponent } from "./multiinsert/multiinsert.component";
import { PexesoComponent } from "./pexeso/pexeso.component";
import { MarkAndReplaceComponent } from "./markandreplace/markandreplace.component";
import { TabledragComponent } from "./tabledrag/tabledrag.component";
import { PexesoFillComponent } from "./pexesofill/pexesofill.component";
import { OrdinalNumbersComponent } from "./ordinalnumbers/ordinalnumbers.component";
import { FreecontentComponent } from "./freecontent/freecontent.component";
import { MatchingdragmulticolComponent } from "./matchingdragmulticol/matchingdragmulticol.component";
import { TruefalseComponent } from "./truefalse/truefalsecomponent";
import { CrosswordComponent } from "./crossword/crossword.component";
import { NewsinglechoiceComponent } from "./newsinglechoice/newsinglechoice.component";
import { FlyingwordsComponent } from "./flyingwords/flyingwords.component";
import { SortingwordsComponent } from "./sortingwords/sortingwords.component";
import { RandomwheelComponent } from "./randomwheel/randomwheel.component";
import { PicturemultiinsertComponent } from "./picturemultiinsert/picturemultiinsert.component";
import { MatchingdragpicturesComponent } from "./matchingdragpictures/matchingdragpictures.component";
import { SortingverbsComponent } from "./sortingverbs/sortingverbs.component";
import { MatchingdraginsideComponent } from "./matchingdraginside/matchingdraginside.component";
import { SingleAudioTextComponent } from "./singleaudiotext/singleaudiotext.component";
import { SingleAudioImageComponent } from "./singleaudioimage/singleaudioimage.component";
import { MultimultifillComponent } from "./multimultifill/multimultifill.component";
import { HangmanComponent } from "./hangman/hangman.component";
import { PexesoAudioComponent } from "./pexesoaudio/pexesoaudio.component";
import { UncoverComponent } from "./uncover/uncover.component";
import { OsmismerkaComponent } from "./osmismerka/osmismerka.component";
import { OpenquestionComponent } from "./openquestion/openquestion.component";
import { ComicComponent } from "./comics/comics.component";
import { ColumnPexesoComponent } from "./columnpexeso/columnpexeso.component";
import { MultilinefillComponent } from "./multilinefill/multilinefill.component";
import { MultilinegapchoiceComponent } from "./multilinegapchoice/multilinegapchoice.component";
import { MultilinesoundfillComponent } from "./multilinesoundfill/multilinesoundfill.component";
import { MultilineaudiochoiceComponent } from "./multilineaudiochoice/multilineaudiochoice.component";
import { MultilinegapselectComponent } from "./multilinegapselect/multilinegapselect.component";
import { AudiogridComponent } from "./audiogrid/audiogrid.component";
import { MatchingdragimageComponent } from "./matchingdragimage/matchingdragimage.component";
import { FillingimageComponent } from "./fillingimage/fillingimage.component";
import { MultilinegapfillComponent } from "./multilinegapfill/multilinegapfill.component";
import { MatchingdragmulticoldupComponent } from "./matchingdragmulticoldup/matchingdragmulticoldup.component";
import { MatchingdraggridComponent } from "./matchingdraggrid/matchingdraggrid.component";
import { OrderdraginlineComponent } from "./orderdraginline/orderdraginline.component";

export const Tasks = {
  multichoice: MultichoiceComponent,
  singlechoice: SinglechoiceComponent,
  multifill: MultifillComponent,
  multidrag: MultidragComponent,
  orderdrag: OrderdragComponent,
  insertorderdrag: InsertorderdragComponent,
  insertanswer: InsertanswerComponent,
  select: SelectComponent,
  calculate: CalculateComponent,
  multiselect: MultiselectComponent,
  multiinsert: MultiinsertComponent,
  matchingdrag: MatchingdragComponent,
  matchingdragcol: MatchingdragcolComponent,
  tablefill: TablefillComponent,
  pexeso: PexesoComponent,
  markandreplace: MarkAndReplaceComponent,
  tabledrag: TabledragComponent,
  pexesofill: PexesoFillComponent,
  ordinalnumbers: OrdinalNumbersComponent,
  freecontent: FreecontentComponent,
  matchingdragmulticol: MatchingdragmulticolComponent,
  truefalse: TruefalseComponent,
  crossword: CrosswordComponent,
  newsinglechoice: NewsinglechoiceComponent,
  flyingwords: FlyingwordsComponent,
  sortingwords: SortingwordsComponent,
  randomwheel: RandomwheelComponent,
  picturemultiinsert: PicturemultiinsertComponent,
  matchingdragpictures: MatchingdragpicturesComponent,
  sortingverbs: SortingverbsComponent,
  matchingdraginside: MatchingdraginsideComponent,
  singleaudiotext: SingleAudioTextComponent,
  singleaudioimage: SingleAudioImageComponent,
  multimultifill: MultimultifillComponent,
  hangman: HangmanComponent,
  pexesoaudio: PexesoAudioComponent,
  uncover: UncoverComponent,
  osmismerka: OsmismerkaComponent,
  openquestion: OpenquestionComponent,
  comics: ComicComponent,
  columnpexeso: ColumnPexesoComponent,
  multilinefill: MultilinefillComponent,
  multilinegapchoice: MultilinegapchoiceComponent,
  multilinegapselect: MultilinegapselectComponent,
  multilineaudiofill: MultilinesoundfillComponent,
  multilineaudiochoice: MultilineaudiochoiceComponent,
  audiogrid: AudiogridComponent,
  matchingdragimage: MatchingdragimageComponent,
  fillingimage: FillingimageComponent,
  multilinegapfill: MultilinegapfillComponent,
  matchingdragmulticoldup: MatchingdragmulticoldupComponent,
  matchingdraggrid: MatchingdraggridComponent,
  orderdraginline: OrderdraginlineComponent
};

const typeNameMapping = {
  multilinefill: "Víceřádkové vepisování",
  multilinegapchoice: "Víceřádkové doplněné z možností",
  comics: "Komiks",
  calculate: "Výpočet s tolerancí",
  insertanswer: "Zapisování odpovědí do sloupce",
  insertorderdrag: "Vkládací drag and drop",
  markandreplace: "Označit a opravit",
  matchingdrag: "Drag and Drop - spojovačka",
  matchingdragcol: "Drag and Drop - spojovačka napříč sloupci",
  multichoice: "Výběr z možností - více správných odpovědí",
  multifill: "Doplňování do textu",
  multiinsert: "Doplňování do textu - vepisování",
  multiselect: "Doplňování do textu - roletky",
  orderdrag: "Drag and Drop - řazení",
  pexeso: "Základní pexeso",
  select: "Výběr z roletky - 1 správná odpověď",
  singlechoice: "Výběr z možností - 1 správná odpověď",
  tabledrag: "Přetahování do tabulky",
  tablefill: "Doplňování do tabulky",
  pexesofill: "Vepisovací pexeso",
  ordinalnumbers: "Řadové číslovky",
  freecontent: "Volný obsah bez cvičení",
  matchingdragmulticol: "Drag and Drop - přesouvání do sloupců",
  truefalse: "Pravdivá/nepravdivá tvrzení",
  crossword: "Křížovka",
  newsinglechoice: "Výběr z možností - 1 správná odpověď",
  flyingwords: "Létající slova",
  sortingwords: "Skládání slov",
  randomwheel: '"Roztočení kola" / náhodný emotikon',
  picturemultiinsert: "Vepisování pod obrázky",
  matchingdragpictures: "Doplňování 2. a 3. stupně přídavných jmen k obrázkům",
  sortingverbs: "Tvary sloves",
  matchingdraginside: "Drag and Drop - přsouvání slov do vět",
  singleaudiotext: "Výběr z možností zvuk - jedna správná odpověď (text)",
  singleaudioimage: "Výběr z možností zvuk - jedna správná odpověď (obrázek)",
  multimultifill: "Doplňování do textu (víceřádkové)",
  hangman: "Šibenice",
  pexesoaudio: "Pexeso (s možností nahrávek)",
  uncover: "Rozkrývání obrázku",
  osmismerka: "Osmisměrka",
  openquestion: "Otevřená otázka",
  columnpexeso: "Pexeso sloupečky",
  multilineaudiofill: "Víceřádkové přepisování z nahrávek",
  multilineaudiochoice: "Víceřádkový výběr z možností s audiem",
  multilinegapselect: "Doplňování do dialogu",
  audiogrid: "Tabulka nahrávek",
  matchingdragimage: "Přetahování do obrázků",
  fillingimage: "Vepisování do obrázků",
  multilinegapfill: "Vepisování do textu s obrázky",
  matchingdragmulticoldup: "Drag and Drop - přesouvání do sloupců s opakováním",
  matchingdraggrid: "Drag and drop - přesouvání do tabulky",
  orderdraginline: "Řazení slov ve větě"
};

export const getTaskName = (type: string) => {
  return typeNameMapping[type];
};
