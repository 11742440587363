import { Component, OnInit, Input, Output, EventEmitter, ViewChildren, TemplateRef, ElementRef, QueryList, Inject, ChangeDetectorRef, ViewEncapsulation, OnChanges, SimpleChange } from '@angular/core';
import { TaskComponent } from '../task';
import * as _ from "lodash";
import { Observable, Subscription } from 'rxjs';
import { Howl } from "howler";

export declare type MultilinesoundfillLine = {
  audio: string;
  correct: string;
}

export declare type MultilinesoundfillData = {
  type?: string;
  question?: string;
  lines: MultilinesoundfillLine[];
};

@Component({
  selector: "app-multilinesoundfill",
  templateUrl: "./multilinesoundfill.component.html",
  styleUrls: ["./multilinesoundfill.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class MultilinesoundfillComponent implements TaskComponent, OnInit {
  @Input() data: MultilinesoundfillData;
  @Output() taskSubmitted: EventEmitter<boolean> = new EventEmitter();
  public isAnswered = false;
  @Input() submitTask: Observable<void>;
  private eventsSubscription: Subscription;
  public isCorrect = false;
  public answers: string[] = [];
  public recordings: Howl[] = [];
  public lines: MultilinesoundfillLine[] = [];

  constructor() {

  }

  ngOnInit() {
    this.eventsSubscription = this.submitTask.subscribe(() => this.submit());
    this.lines = this.data.lines;
    this.answers = Array(this.lines.length).fill("");
    this.recordings = this.data.lines.map((line) => new Howl({src: [ line.audio ]}));
  }

  ngOnChanges() {
    this.lines = this.data.lines;
    this.answers = Array(this.lines.length).fill("");
    this.recordings = this.data.lines.map((line) => new Howl({src: [ line.audio ]}));

  }

  ngOnDestroy() {
    this.eventsSubscription.unsubscribe();
  }


  submit() {
    console.log("SUBMITTED")
    this.isCorrect = true;
    for (let answerIndex in this.answers) {
      if(this.answers[answerIndex] != this.lines[answerIndex].correct) {
        this.isCorrect = false;
      }
    }
    this.taskSubmitted.emit(this.isCorrect);
    this.isAnswered = true;
  }
}
