import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './admin/admin.component';
import { AuthGuard } from './auth.guard';


const routes: Routes = [
  { path: 'admin/:dvz', canActivate: [AuthGuard], component: AdminComponent },
  {
    path: 'dikobraz-se-uci-rusky',
    loadChildren: () => import('./dikobraz-se-uci-rusky/dikobraz-se-uci-rusky.module').then(m => m.DikobrazSeUciRuskyModule)
  },
  {
    path: 'dikobraz-se-uci-nemecky',
    loadChildren: () => import('./dikobraz-se-uci-nemecky/dikobraz-se-uci-nemecky.module').then(m => m.DikobrazSeUciNemeckyModule)
  },
  {
    path: 'dikobraz-se-uci-chemii',
    loadChildren: () => import('./dikobraz-se-uci-chemii/dikobraz-se-uci-chemii.module').then(m => m.DikobrazSeUciChemiiModule)
  },
  {
    path: 'dikobraz-se-uci-fyziku',
    loadChildren: () => import('./dikobraz-se-uci-fyziku/dikobraz-se-uci-fyziku.module').then(m => m.DikobrazSeUciFyzikuModule)
  },
  {
    path: 'dikobraz-3d-tiskne-a-modeluje',
    loadChildren: () => import('./dikobraz-3d-tiskne-a-modeluje/dikobraz-3d-tiskne-a-modeluje.module').then(m => m.Dikobraz3dTiskneAModelujeModule)
  },
  {
    path: 'dikobraz-se-uci-francouzsky',
    loadChildren: () => import('./dikobraz-se-uci-francouzsky/dikobraz-se-uci-francouzsky.module').then(m => m.DikobrazSeUciFrancouzskyModule)
  },
  {
    path: 'dikobraz-se-uci-cesky',
    loadChildren: () => import('./dikobraz-se-uci-cesky/dikobraz-se-uci-cesky.module').then(m => m.DikobrazSeUciCeskyModule)
  },
  {
    path: 'dikobraz-se-uci-anglicky',
    loadChildren: () => import('./dikobraz-se-uci-anglicky/dikobraz-se-uci-anglicky.module').then(m => m.DikobrazSeUciAnglickyModule)
  },
  {
    path: 'dikobraz-pise',
    loadChildren: () => import('./dikobraz-pise/dikobraz-pise.module').then(m => m.DikobrazPiseModule)
  },
  {
    path: 'dikobraz-konstruuje',
    loadChildren: () => import('./dikobraz-konstruuje/dikobraz-konstruuje.module').then(m => m.DikobrazKonstruujeModule)
  },
  {
    path: 'dikobraz-hospodari',
    loadChildren: () => import('./dikobraz-hospodari/dikobraz-hospodari.module').then(m => m.DikobrazHospodariModule)
  },
  {
    path: 'dikobraz-cte',
    loadChildren: () => import('./dikobraz-cte/dikobraz-cte.module').then(m => m.DikobrazCteModule)
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [AuthGuard]
})
export class DvzRoutingModule { }
