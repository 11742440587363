import { Component, OnInit, Input, Output, EventEmitter, ViewChildren, TemplateRef, ElementRef, QueryList, Inject, ChangeDetectorRef, ViewEncapsulation, OnChanges } from '@angular/core';
import { TaskComponent } from '../task';
import { DOCUMENT } from '@angular/common';
import * as _ from "lodash";
import { Paragraph } from 'src/app/dvz/dikobraz-3d-tiskne-a-modeluje/models/task.model';
import { Observable, Subscription } from 'rxjs';

export declare type MarkAndReplaceData = {
  type?: string;
  question: Paragraph[];
  text: string;
  mark: string;
  correct: string;
};

@Component({
  selector: 'app-markandreplace',
  templateUrl: './markandreplace.component.html',
  styleUrls: ['./markandreplace.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class MarkAndReplaceComponent implements TaskComponent, OnInit, OnChanges {
  @Input() data: MarkAndReplaceData;
  @Input() submitTask: Observable<void>;
  private eventsSubscription: Subscription;
  @Output() taskSubmitted: EventEmitter<boolean> = new EventEmitter();

  public isAnswered = false;
  public selected = null;
  public answer = null;
  public words = [];

  constructor() { 

  }

  ngOnInit() {
    if(this.data) {
      this.words = this.data.text.split(" ");
    }
    this.eventsSubscription = this.submitTask.subscribe(() => this.submit())
  }

  ngOnChanges() {
    if(this.data) {
      this.words = this.data.text.split(" ");
    }
  }

  isSelected(): boolean {
    return this.selected != null;
  }

  isFilled(): boolean {
    return this.answer != null;
  }

  select(word: string) {
    if(this.selected == word) {
      this.selected = null;
    } else {
      this.selected = word;
    }
  }


  submit() {
    if(this.selected == this.data.mark && this.answer == this.data.correct) {
      this.taskSubmitted.emit(true);
    } else {
      this.taskSubmitted.emit(false);
    }
    this.isAnswered = true;
  }

  ngOnDestroy() {
    this.eventsSubscription.unsubscribe();
  }
}
