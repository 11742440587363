import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewEncapsulation,
} from "@angular/core";
import * as _ from "lodash";
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from "@angular/cdk/drag-drop";
import { Paragraph } from "src/app/dvz/dikobraz-3d-tiskne-a-modeluje/models/task.model";
import { TaskComponent } from "../task";
import { Observable, Subscription } from "rxjs";

declare type MatchingdragcolOption = {
  leftValue: string;
  rightValue: string;
  isExample: boolean;
};

export declare type MatchingdragcolData = {
  type?: string;
  question?: string;
  imagesInLeft: boolean;
  exampleInQuestion: boolean;
  options: MatchingdragcolOption[];
};

@Component({
  selector: "app-matchingdragcol",
  templateUrl: "./matchingdragcol.component.html",
  styleUrls: ["./matchingdragcol.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class MatchingdragcolComponent
  implements OnChanges, OnInit, TaskComponent
{
  @Input() data: MatchingdragcolData;
  @Output() taskSubmitted: EventEmitter<boolean> = new EventEmitter();
  @Input() submitTask?: Observable<void>;
  private eventsSubscription: Subscription;
  public examples: MatchingdragcolOption[] = []
  public unsorted: string[] = [];
  public leftValues: string[] = [];
  public dragIns: string[][] = [];
  public isAnswered: boolean = false;
  private isCorrect: boolean[] = [];

  constructor() {}

  ngOnInit() {
    this.eventsSubscription = this.submitTask.subscribe(() => this.submit());
    console.log(this.data);
    this.examples = this.data.options.filter((option: MatchingdragcolOption) => option.isExample);
    let withoutExamples = this.data.options.filter((option: MatchingdragcolOption) => !option.isExample);
    this.unsorted = _.shuffle(
      withoutExamples.map((option) => option.rightValue) 
    );
    this.leftValues = withoutExamples.map((option) => option.leftValue); 
    for (let i in this.leftValues) {
      this.dragIns[i] = [];
    }
  }

  ngOnChanges() {
    console.log("CHANGE", this.data);
    this.unsorted = _.shuffle(
      _.filter(this.data.options, (option: MatchingdragcolOption) => !option.isExample)
    ).map((option) => option.rightValue);
    for (let i in this.leftValues) {
      this.dragIns[i] = [];
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    console.log("DROP");
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      console.log(event.container);
      if (event.container.data.length == 0 || event.container.id == "options") {
        transferArrayItem(
          event.previousContainer.data,
          event.container.data,
          event.previousIndex,
          event.currentIndex
        );
      }
    }
  }

  submit() {
    this.isCorrect = [];
    let withoutExamples = this.data.options.filter((option: MatchingdragcolOption) => !option.isExample);
    for (let i in withoutExamples) {
      console.log(withoutExamples[i], this.dragIns[i][0]); 
      let correctAns = this.dragIns[i][0] == withoutExamples[i].rightValue;
      this.isCorrect.push(correctAns);
    }
    if (_.every(this.isCorrect)) {
      this.taskSubmitted.emit(true);
    } else {
      this.taskSubmitted.emit(false);
    }
    this.isAnswered = true;
  }

  isCorrectAnswer(i) {
    let withoutExamples = this.data.options.filter((option: MatchingdragcolOption) => !option.isExample);
    return this.dragIns[i][0] == withoutExamples[i].rightValue;
  }

  getCorrectAnswer(i) {
    return this.data.options.filter((option: MatchingdragcolOption) => !option.isExample)[i].rightValue;
  }

  ngOnDestroy() {
    this.eventsSubscription.unsubscribe();
  }
}
