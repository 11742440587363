import { Component, OnInit, Input, Output, EventEmitter, ViewChildren, TemplateRef, ElementRef, QueryList, Inject, ChangeDetectorRef, ViewEncapsulation, OnChanges, SimpleChange } from '@angular/core';
import { TaskComponent } from '../task';
import * as _ from "lodash";
import { Observable, Subscription } from 'rxjs';

export declare type MultilinegapselectLine = {
  image: string;
  contentBefore?: string;
  contentAfter?: string;
  correct?: string;
  options?: string;
}

export declare type MultilinegapselectData = {
  type?: string;
  question?: string;
  lines: MultilinegapselectLine[];
};

@Component({
  selector: "app-multilinegapselect",
  templateUrl: "./multilinegapselect.component.html",
  styleUrls: ["./multilinegapselect.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class MultilinegapselectComponent implements TaskComponent, OnInit {
  @Input() data: MultilinegapselectData;
  @Output() taskSubmitted: EventEmitter<boolean> = new EventEmitter();
  public isAnswered = false;
  @Input() submitTask: Observable<void>;
  private eventsSubscription: Subscription;
  public isCorrect = false;
  public answers: string[] = [];
  public lines: MultilinegapselectLine[] = [];

  constructor() {

  }

  ngOnInit() {
    this.eventsSubscription = this.submitTask.subscribe(() => this.submit());
    this.lines = this.data.lines;
    this.answers = Array(this.lines.length).fill(undefined);
  }

  ngOnChanges() {
    this.lines = this.data.lines
    this.answers = Array(this.lines.length).fill(undefined);
  }

  ngOnDestroy() {
    this.eventsSubscription.unsubscribe();
  }


  submit() {
    console.log("SUBMITTED")
    this.isCorrect = true;
    for (let answerIndex in this.answers) {
      if(this.answers[answerIndex] != this.lines[answerIndex].correct) {
        this.isCorrect = false;
      }
    }
    this.taskSubmitted.emit(this.isCorrect);
    this.isAnswered = true;
  }
}
