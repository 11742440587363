import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  Inject,
  ViewEncapsulation,
  ChangeDetectorRef,
} from "@angular/core";
import { TaskComponent } from "../task";
import { DOCUMENT } from "@angular/common";
import * as _ from "lodash";
import { matchAll } from "@shared/utilities";
import { compareStringAndArray } from "@shared/utilities/compare-strings";
import { Paragraph } from "src/app/dvz/dikobraz-se-uci-anglicky/models/task.model";

declare type Row = { imageUrl?: string; emoticon?: string; rowScheme: string };

export declare type MultiinsertData = {
  type?: string;
  question: Paragraph[];
  backgroundAudioUrl?: string;
  rows: Row[];
};

@Component({
  selector: "app-multiinsert",
  templateUrl: "./multiinsert.component.html",
  styleUrls: ["./multiinsert.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class MultiinsertComponent implements TaskComponent, OnInit {
  @Input() data: MultiinsertData;
  @Output() taskSubmitted: EventEmitter<boolean> = new EventEmitter();
  private document;
  public isAnswered = false;
  public isCorrect: boolean[] = [];
  public filledTemplate = "";
  public questions: {
    correct: string[];
    hint: string;
    id: string;
  }[] = [];
  private audio: HTMLAudioElement;

  constructor(
    @Inject(DOCUMENT) document,
    private changeRef: ChangeDetectorRef
  ) {
    this.document = document;
  }

  ngOnInit() {
    this.createGaps();
    if (this.data.backgroundAudioUrl) {
      this.audio = new Audio(this.data.backgroundAudioUrl);
      this.audio.play();
    }
  }

  ngOnDestroy() {
    this.stopAudio();
  }

  submit() {
    this.isCorrect = [];
    for (let answerIndex in this.questions) {
      let answer = this.questions[answerIndex];
      let userAnswer: string = this.getAnswer(parseInt(answerIndex));
      this.isCorrect.push(compareStringAndArray(userAnswer, answer.correct));
      let inputEl = this.document.getElementById(
        this.questions[answerIndex].id
      );
      const newEl = this.document.createElement("span");
      newEl.classList.add(
        compareStringAndArray(userAnswer, answer.correct)
          ? "correct"
          : "incorrect",
        "answer"
      );
      newEl.innerHTML = userAnswer;
      if (this.questions[answerIndex].hint) {
        inputEl.parentNode.removeChild(inputEl.nextSibling);
      }
      inputEl.parentNode.replaceChild(newEl, inputEl);
      if (!compareStringAndArray(userAnswer, answer.correct)) {
        const correctAnswer = this.document.createElement("span");
        correctAnswer.classList.add("correct-answer");
        correctAnswer.innerHTML =
          "( správná odpověď: " + answer.correct.join(", ") + ")";
        newEl.parentNode.insertBefore(correctAnswer, newEl.nextSibling);
      }
    }
    if (_.every(this.isCorrect)) {
      this.taskSubmitted.emit(true);
    } else {
      this.taskSubmitted.emit(false);
    }
    this.isAnswered = true;
    this.stopAudio();
  }

  stopAudio() {
    if (this.audio) {
      this.audio.pause();
      this.audio.remove();
    }
  }

  getAnswer(id: number) {
    if (!this.document.getElementById(this.questions[id].id)) {
      return null;
    }
    this.document.getElementById(this.questions[id].id).onchange = (e) => {
      if (this.changeRef) {
        this.changeRef.detectChanges();
      }
    };
    return this.document.getElementById(this.questions[id].id).value;
  }

  isAllFilled() {
    let allFilled = true;
    if (!this.data) {
      return false;
    }
    for (let answerIndex in this.questions) {
      if (!this.getAnswer(parseInt(answerIndex))) {
        allFilled = false;
      }
    }
    return allFilled;
  }

  insertImages(row: Row) {
    return row.rowScheme.replace(
      "{#IMAGE}",
      `<img class="text-image" src="${row.imageUrl}"/>`
    );
  }

  createGaps() {
    let template: string;
    if (this.isContainImages()) {
      template = this.data.rows
        .map((row) => this.insertImages(row))
        .join("<br>");
    } else {
      template = this.data.rows.map((row) => row.rowScheme).join("<br>");
    }
    this.questions = [];
    let gapMatches = matchAll(/\{#INSERT:([^}*]+)\}/g, template);
    for (let i in gapMatches) {
      let questionTemplate = gapMatches[i][0]
        .replace("{#INSERT:", "")
        .replace("}", "");
      let correct = questionTemplate.split(":")[0].split(",");
      let hint =
        questionTemplate.split(":").length > 1
          ? `(${questionTemplate.split(":")[1]})`
          : "";
      this.questions.push({
        correct: correct.map((correct: string) => correct.trim()),
        hint: hint,
        id: "insert-" + i,
      });
    }
    let gappedText = template;
    for (let i in this.questions) {
      let emoticon = "";
      if (this.data.rows[i]) {
        emoticon = this.data.rows[i].emoticon ? this.data.rows[i].emoticon : "";
      }
      const hint = `${emoticon} ${this.questions[i].hint}`;
      gappedText = gappedText.replace(
        gapMatches[i][0],
        '<input class="answer" id="' +
          this.questions[i].id +
          '">' +
          "</input><span>" +
          hint.trim() +
          "</span>"
      );
    }
    this.filledTemplate = gappedText;
  }

  isContainImages(): boolean {
    for (let row of this.data.rows) {
      if (row.imageUrl && row.imageUrl !== undefined && row.imageUrl !== "") {
        return true;
      }
    }
    return false;
  }
}
