import { Component, OnInit, Input, Output, EventEmitter, ViewChildren, TemplateRef, ElementRef, QueryList, Inject, ChangeDetectorRef, ViewEncapsulation, OnChanges, SimpleChange } from '@angular/core';
import { TaskComponent } from '../task';
import { DOCUMENT } from '@angular/common';
import * as _ from "lodash";
import { matchAll } from '@shared/utilities';
import { Observable, Subscription } from 'rxjs';

export declare type MultifillData = {
  type?: string;
  question?: string;
  template: string;
};

@Component({
  selector: "app-multifill",
  templateUrl: "./multifill.component.html",
  styleUrls: ["./multifill.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class MultifillComponent implements TaskComponent, OnInit, OnChanges {
  @Input() data: MultifillData;
  @Output() taskSubmitted: EventEmitter<boolean> = new EventEmitter();
  private document;
  public isAnswered = false;
  @Input() submitTask: Observable<void>;
  private eventsSubscription: Subscription;
  public isCorrect: boolean[] = [];
  public answers: { id: string; value: string }[];

  constructor(
    @Inject(DOCUMENT) document,
    private changeRef: ChangeDetectorRef
  ) {
    this.document = document;
  }

  ngOnInit() {
    this.eventsSubscription = this.submitTask.subscribe(() => this.submit())
    if(this.data) {
      console.log("INIT GAPS")
      this.createGaps();
    }
  }

  ngOnDestroy() {
    this.eventsSubscription.unsubscribe();
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }) {
    if( changes['data'] && changes['data'].previousValue != changes['data'].currentValue ) {
      console.log("CHANGE GAPS")
      this.createGaps();
    }
  }

  submit() {
    console.log("SUBMITTED")
    this.isCorrect = [];
    for (let answerIndex in this.answers) {
      let answer = this.answers[answerIndex].value.split("|");
      let userAnswer = this.getAnswer(parseInt(answerIndex));
      this.isCorrect.push(answer.includes(userAnswer));
      let inputEl = this.document.getElementById(
        this.answers[answerIndex].id
      );
      const newEl = this.document.createElement("span");
      newEl.classList.add(
        answer.includes(userAnswer) ? "correct" : "incorrect",
        "answer"
      );
      newEl.innerHTML = userAnswer;
      inputEl.parentNode.replaceChild(newEl, inputEl);
      if (!answer.includes(userAnswer)) {
        const correctAnswer = this.document.createElement("span");
        correctAnswer.classList.add("correct-answer");
        correctAnswer.innerHTML = "( správná odpověď: " + answer.join(" / ") + ")";
        newEl.parentNode.insertBefore(correctAnswer, newEl.nextSibling);
      }
    }
    if (_.every(this.isCorrect)) {
      this.taskSubmitted.emit(true);
    } else {
      this.taskSubmitted.emit(false);
    }
    this.isAnswered = true;
  }

  getAnswer(id: number) {
    if (!this.document.getElementById(this.answers[id].id)) {
      return null;
    }
    this.document.getElementById(this.answers[id].id).onchange = function (
      e
    ) {
      if (this.changeRef) {
        this.changeRef.detectChanges();
      }
    };
    return this.document.getElementById(this.answers[id].id).value;
  }

  public isAllFilled() {
    let allFilled = true;
    if (!this.data) {
      return false;
    }
    for(let answerIndex in this.answers) {
      if(!this.getAnswer(parseInt(answerIndex))) {
        allFilled = false;
      }
    }
    return allFilled;
  }

  createGaps() {
    let gaps = []
    let gapMatches = matchAll(/\{#SELECT(FULL)?:([^}*]+)\}/g, this.data.template);
    for(let i in gapMatches) {
      let isFull = gapMatches[i][0].includes("#SELECTFULL");
      gaps.push({
        value: gapMatches[i][0].replace(/\{#SELECT(FULL)?:/, "").replace("}", ""),
        id: 'fill-'+i,
        full: isFull
      });
    }
    let gappedText = this.data.template;
    for(let i in gaps) {
      gappedText = gappedText.replace(gapMatches[i][0], '<input class="multifill'+ (gaps[i].full ? ' full-width' : '') +'" id="'+gaps[i].id+'">');
    }
    console.log(gappedText);
    this.data.template = gappedText;
    this.answers =  gaps;
  }
}
