import { HttpClient } from '@angular/common/http';
import { ValueConverter } from '@angular/compiler/src/render3/view/template';
import { Component, HostListener, OnInit } from '@angular/core';
import { HasSubscriptions } from '@shared/utilities';
import * as _ from 'lodash';

@Component({
  selector: 'app-course',
  templateUrl: './course.component.html',
  styleUrls: ['./course.component.css']
})
export class CourseComponent  extends HasSubscriptions implements OnInit {


  public isLoading = true;
  public courseList: any[] = [];
  public innerWidth: any;

  constructor(private files: HttpClient) {
    super();
    this.loadLessons();
  }

  private loadLessons() {
    this.safeSubscribe(
      this.files.get('/assets/data/lessons.json'),
      (fileContent) => {
        this.isLoading = false;
        console.log(fileContent);
        const ids = _.keys(fileContent);
        this.courseList = _.map(_.values(fileContent), (value: any, index: number) => ({...value, id: ids[index]}));
        console.log(this.courseList, _.isArray(this.courseList));
      }
    );
  }

  ngOnInit() {
    this.innerWidth = window.innerWidth;
  }

  colNum() {
    return this.innerWidth < 500 ?  "1" : 
           this.innerWidth < 900 ? "2" : 
           this.innerWidth < 1400 ? "3" :
           "4";
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }

}
