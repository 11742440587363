import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from "@angular/core";
import * as _ from "lodash";
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from "@angular/cdk/drag-drop";
import { Paragraph } from "src/app/dvz/dikobraz-3d-tiskne-a-modeluje/models/task.model";

declare type Sentence = {
  firstPart: string;
  correct: string;
  secondPart: string;
};

export declare type MatchingdraginsideData = {
  type?: string;
  question: string;
  sentences: Sentence[];
};

@Component({
  selector: "app-matchingdraginside",
  templateUrl: "./matchingdraginside.component.html",
  styleUrls: ["./matchingdraginside.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class MatchingdraginsideComponent {
  @Input() data: MatchingdraginsideData;
  @Output() taskSubmitted: EventEmitter<boolean> = new EventEmitter();
  public options: string[] = [];
  public dragIns: string[][] = [];
  public isAnswered: boolean = false;
  private isCorrect: boolean[] = [];

  constructor() {}

  ngOnInit() {
    console.log(this.data);
    this.options = _.shuffle(
      this.data.sentences.map((sentence) => sentence.correct)
    );
    console.log(this.options);

    for (let i in this.options) {
      this.dragIns[i] = [];
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      console.log(event.container);
      if (event.container.data.length == 0 || event.container.id == "options") {
        transferArrayItem(
          event.previousContainer.data,
          event.container.data,
          event.previousIndex,
          event.currentIndex
        );
      }
    }
  }

  submit() {
    const isCorrect = [];
    for (let i in this.data.sentences) {
      let correctAns = this.dragIns[i][0] == this.data.sentences[i].correct;
      isCorrect.push(correctAns);
      this.isCorrect = _.every(isCorrect);
    }
    if (this.isCorrect) {
      this.taskSubmitted.emit(true);
    } else {
      this.taskSubmitted.emit(false);
    }
    this.isAnswered = true;
  }

  setColor(word, i) {
    if (!this.isAnswered) {
      return "";
    }
    if (word === this.data.sentences[i].correct) {
      return "correct";
    } else {
      return "incorrect";
    }
  }
}
