import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from "@angular/core";
import * as _ from "lodash";
import { CdkDragDrop, transferArrayItem } from "@angular/cdk/drag-drop";
import { TaskComponent } from "../task";
import { compareStrings } from "@shared/utilities/compare-strings";

declare type SortingResult = {
  sentence: string;
  correctTokens: string[];
  shuffledDragIns: string[][];
};

export declare type SortingwordsData = {
  type?: string;
  question: string;
  sentences: string[];
};

@Component({
  selector: "app-sortingwords",
  templateUrl: "./sortingwords.component.html",
  styleUrls: ["./sortingwords.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class SortingwordsComponent implements OnInit, TaskComponent {
  @Input() data: SortingwordsData;
  @Output() taskSubmitted: EventEmitter<boolean> = new EventEmitter();

  public results: SortingResult[] = [];
  public activeSentence: SortingResult;
  public currentIndex: number = 0;
  public isChecking = false;
  public isAnswered: boolean = false;
  private isCorrect: boolean[] = [];

  constructor() {}

  ngOnInit() {
    this.data.sentences.forEach((sentence) => {
      const tokens = this.getTokens(sentence);
      this.results.push({
        sentence: sentence,
        correctTokens: tokens,
        shuffledDragIns: _.shuffle(this.getDragIns(tokens)),
      });
    });
    this.setUp();
  }

  setUp() {
    this.activeSentence = this.results[this.currentIndex];
  }

  getDragIns(tokens: string[]) {
    const dragIns = [];
    tokens.forEach((token) => {
      dragIns.push([token]);
    });
    return dragIns;
  }

  getTokens(sentence: string) {
    return sentence.trim().split(" ");
  }

  drop(event: CdkDragDrop<string[]>) {
    transferArrayItem(event.previousContainer.data, event.container.data, 0, 0);
    transferArrayItem(event.container.data, event.previousContainer.data, 1, 0);
  }

  check() {
    this.isChecking = true;
    this.isCorrect.push(
      _.isEqual(
        this.activeSentence.correctTokens,
        this.activeSentence.shuffledDragIns.map((dragIn) => dragIn.toString())
      )
    );
    if (this.isCorrect.length === this.results.length) {
      this.submit();
    }
  }

  next() {
    this.isChecking = false;
    this.currentIndex++;
    this.setUp();
  }

  submit() {
    if (_.every(this.isCorrect)) {
      this.taskSubmitted.emit(true);
    } else {
      this.taskSubmitted.emit(false);
    }
    this.isAnswered = true;
  }

  setColor(word, i) {
    if (!this.isChecking) {
      return "";
    }
    if (compareStrings(word, this.activeSentence.correctTokens[i])) {
      return "correct";
    } else {
      return "incorrect";
    }
  }
}
