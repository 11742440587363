import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { TaskComponent } from "../task";
import * as _ from "lodash";
import { Howl } from 'howler';

declare type Option = {
  id: number;
  content: string;
  isAnswered: boolean;
};

export declare type UncoverData = {
  type?: string;
  question: string;
  mainImageUrl: string;
  questions: {
    text: string;
    audioUrl: string;
  }[];
};

@Component({
  selector: "app-uncover",
  templateUrl: "./uncover.component.html",
  styleUrls: ["./uncover.component.css"],
})
export class UncoverComponent implements TaskComponent, OnInit {
  @Input() data: UncoverData;
  @Output() taskSubmitted: EventEmitter<boolean> = new EventEmitter();

  public isAnswered: boolean = false;
  public options: Option[] = [];
  public recordings: {audio: Howl, id: number}[] = [];
  public currentRecording: number = 0;
  public size: number;
  public mainSize: number = 600;
  public cols: number;

  constructor() {}

  ngOnInit() {
    let i = 0;
    this.recordings = [];
    this.options = [];
    this.data.questions.forEach((question) => {
      this.options.push({
        id: i,
        content: question.text,
        isAnswered: false,
      });
      this.recordings.push({
        id: i,
        audio: new Howl({
          src: [  question.audioUrl ]
        })
      });
      i++;
    });
    this.options = this.options;
    this.recordings = _.shuffle(this.recordings);
    this.cols = this.data.questions.length / 2 === 1 ? 2 : this.data.questions.length / 2;
    if(this.cols == 8) {
      this.cols = 4;
    }
    this.size = this.mainSize / this.cols;
  }

  select(option: Option) {
    if(this.recordings[this.currentRecording].id == option.id) {
      //correct
      option.isAnswered = true;
      if(this.currentRecording < this.recordings.length - 1) {
        this.currentRecording++;
      } else {
        this.taskSubmitted.emit(true);
      }
    }
  }

  setVisibility(question: Option) {
    if (question.isAnswered) {
      return "non-visible";
    } else {
      return "visible";
    }
  }
}
