import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatButtonModule, MatCheckboxModule, MatInputModule, MatNativeDateModule,
         MatSlideToggleModule, MatStepperModule, MatTooltipModule, MatSidenavModule,
         MatTableModule, MatCardModule, MatDatepickerModule, MatExpansionModule,
         MatIconModule, MatToolbarModule, MatSnackBarModule } from '@angular/material';
// import { CdkTableModule } from '@angular/cdk';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MiscComponent } from './misc.component';
import { TableComponent } from './table/table.component';
import { StepperComponent } from './stepper/stepper.component';
import { DragDropComponent } from './drag-drop/drag-drop.component';
import { ExpansionPanelComponent } from './expansion-panel/expansion-panel.component';
import { DataManagerComponent } from './data-manager/data-manager.component';
import { ParagraphsComponent } from './paragraphs/paragraphs.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { DvzHeaderComponent } from './dvz-header/dvz-header.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    MiscComponent,
    TableComponent,
    StepperComponent,
    DragDropComponent,
    ExpansionPanelComponent,
    DataManagerComponent,
    ParagraphsComponent,
    DvzHeaderComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatButtonModule, MatCheckboxModule, MatInputModule, MatNativeDateModule,
    MatSlideToggleModule, MatStepperModule, MatTooltipModule, MatSidenavModule,
    MatTableModule, MatCardModule, MatDatepickerModule, MatExpansionModule,
    MatIconModule, MatToolbarModule,MatSnackBarModule,
    FormsModule, ReactiveFormsModule,
    DragDropModule,
    RouterModule,
    NgxPaginationModule,
    
  ],
  exports: [
    MiscComponent,
    TableComponent,
    StepperComponent,
    ExpansionPanelComponent,
    DataManagerComponent,
    ParagraphsComponent,
    DvzHeaderComponent
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})

export class MiscModule {
}
