import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { TaskComponent } from "../task";
import * as _ from "lodash";

export declare type HangmanData = {
  type?: string;
  question?: string;
  image?: string;
  letters: string;
  correct: string;
};

@Component({
  selector: "app-hangman",
  templateUrl: "./hangman.component.html",
  styleUrls: ["./hangman.component.css"],
})
export class HangmanComponent implements TaskComponent, OnInit {
  @Input() data: HangmanData;
  @Output() taskSubmitted: EventEmitter<boolean> = new EventEmitter();
  public isAnswered: boolean = false;
  public guess: string = "";
  public lives: number = 13;
  public positions: string[] = [];

  constructor() {}

  ngOnInit() {
    this.initializeGame();
  }

  sendGuess() {
    let hit = false;
    for(let i in _.range(this.data.correct.length)) {
      if(this.data.correct[i].toUpperCase() == this.guess.toUpperCase()) {
        this.positions[i] = this.guess.toUpperCase();
        hit = true;
      }
    }
    if(hit == false) {
      this.lives--;
    }
    if(this.lives == 0) {
      this.taskSubmitted.emit(false);
      for(let i in _.range(this.data.correct.length)) {
        this.positions[i] = this.guess.toUpperCase();
      }
      this.isAnswered = true;
    }
    if(this.positions.join("").toUpperCase() == this.data.correct.toUpperCase()) {
      this.taskSubmitted.emit(true);
    }
    this.guess = "";
  }

  private initializeGame() {
    this.positions = new Array(this.data.correct.length).fill("_");
    if(this.data.letters) {
      let fillPosition: number[] = this.data.letters.split(",").map((pos: string) => parseInt(pos) - 1);
      for(let position of fillPosition) {
        this.positions[position] = this.data.correct[position].toUpperCase();
      }
    }
    this.lives = 13;
    this.isAnswered = false;
    this.guess = "";
  }
}
