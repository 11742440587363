import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewEncapsulation } from '@angular/core';
import * as _ from "lodash";
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Observable, Subscription } from 'rxjs';

export declare type OrderdragData = {
  type?: string;
  question: string;
  imageUrl?: string;
  options: string[];
  isNumbers: boolean;
};

@Component({
  selector: 'app-orderdrag',
  templateUrl: './orderdrag.component.html',
  styleUrls: ['./orderdrag.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class OrderdragComponent implements OnChanges {

  @Input() data: OrderdragData;
  @Input() submitTask: Observable<void>;
  private eventsSubscription: Subscription;
  @Output() taskSubmitted: EventEmitter<boolean> = new EventEmitter();
  public options: string[] = [];
  public dragIns: string[][] = [];
  public isAnswered: boolean = false;
  private isCorrect: boolean[] = [];

  constructor(private changeRef: ChangeDetectorRef) { 

  }

  ngOnInit() {
    console.log(this.data);
    this.options = _.shuffle(this.data.options);
    for(let i in this.options) {
      this.dragIns[i] = [];
    }
    this.eventsSubscription = this.submitTask.subscribe(() => this.submit())
  }

  ngOnChanges() {
    console.log("CHANGE",this.data);
    this.options = _.shuffle(this.data.options);
    for(let i in this.options) {
      this.dragIns[i] = [];
    }

  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      console.log(event.container)
      if(event.container.data.length == 0 || event.container.id == "options") {
        transferArrayItem(event.previousContainer.data,
          event.container.data,
          event.previousIndex,
          event.currentIndex);
      }
    }
  }
  
  submit() {
    this.isCorrect = [];
    for(let i in this.data.options) {
        let correctAns = this.dragIns[i][0] == this.data.options[i];
        this.isCorrect.push(correctAns);
    }
    if(_.every(this.isCorrect)) {
      this.taskSubmitted.emit(true);
    } else {
      this.taskSubmitted.emit(false);
    }
    this.isAnswered = true;
  }

  ngOnDestroy() {
    this.eventsSubscription.unsubscribe();
  }



}

