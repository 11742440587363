import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TaskComponent } from '../task';

export declare type CalculateData = {
  type?: string;
  question: string;
  tolerance: number;
  correct: number;
};

@Component({
  selector: 'app-calculate',
  templateUrl: './calculate.component.html',
  styleUrls: ['./calculate.component.css']
})
export class CalculateComponent implements TaskComponent, OnInit {

  @Input() data: CalculateData;
  @Output() taskSubmitted: EventEmitter<boolean> = new EventEmitter();
  public answer: number;
  public isAnswered: boolean = false;
  
  constructor() { }

  ngOnInit() {
  }

  submit() {
    if(Math.abs(this.answer - this.data.correct) <= this.data.tolerance) {
      this.taskSubmitted.emit(true);
    } else {
      this.taskSubmitted.emit(false);
    }
    this.isAnswered = true;
  }

}
