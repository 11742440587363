import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TaskComponent } from '../task';
import * as _ from "lodash";
import { Howl } from 'howler';


export declare type ComicsData = {
  type?: string;
  question: string;
  audioUrl: string | Howl;
  imageUrl: string;
};

@Component({
  selector: 'app-comics',
  templateUrl: './comics.component.html',
  styleUrls: ['./comics.component.css']
})
export class ComicComponent implements TaskComponent, OnInit {

  @Input() data: ComicsData;
  @Output() taskSubmitted: EventEmitter<boolean> = new EventEmitter();
  
  constructor() { 

  }

  ngOnInit() {
    this.data.audioUrl = new Howl({
      src: [ this.data.audioUrl ]
    });
  }

}
