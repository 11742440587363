import { Component, OnInit, Input, Output, EventEmitter, ViewChildren, TemplateRef, ElementRef, QueryList, Inject, ChangeDetectorRef, ViewEncapsulation, OnChanges, SimpleChange } from '@angular/core';
import { TaskComponent } from '../task';
import * as _ from "lodash";
import { Observable, Subscription } from 'rxjs';

export declare type Content = {
  type: "image" | "text";
  content: string;
}
export declare type MultilinegapfillLine = {
  contentBefore?: Content[];
  contentAfter?: Content[];
  correct: string;
  columnCount: number;
}

export declare type MultilinegapfillData = {
  type?: string;
  question?: string;
  lines: MultilinegapfillLine[];
};

@Component({
  selector: "app-multilinegapfill",
  templateUrl: "./multilinegapfill.component.html",
  styleUrls: ["./multilinegapfill.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class MultilinegapfillComponent implements TaskComponent, OnInit {
  @Input() data: MultilinegapfillData;
  @Output() taskSubmitted: EventEmitter<boolean> = new EventEmitter();
  public isAnswered = false;
  @Input() submitTask: Observable<void>;
  private eventsSubscription: Subscription;
  public isCorrect = false;
  public answers: string[] = [];
  public lines: MultilinegapfillLine[] = [];

  constructor() {

  }

  ngOnInit() {
    this.eventsSubscription = this.submitTask.subscribe(() => this.submit());
    this.lines = this.data.lines;
    this.answers = Array(this.lines.length).fill("");
  }

  ngOnChanges() {
    this.lines = this.data.lines;
    this.answers = Array(this.lines.length).fill("");
  }

  ngOnDestroy() {
    this.eventsSubscription.unsubscribe();
  }


  submit() {
    console.log("SUBMITTED")
    this.isCorrect = true;
    for (let answerIndex in this.answers) {
      if(this.answers[answerIndex] != this.lines[answerIndex].correct) {
        this.isCorrect = false;
      }
    }
    this.taskSubmitted.emit(this.isCorrect);
    this.isAnswered = true;
  }
}
