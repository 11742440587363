import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { TaskComponent } from "../task";
import { Paragraph } from "src/app/dvz/dikobraz-3d-tiskne-a-modeluje/models/task.model";
import * as _ from "lodash";
import { compareStrings } from "@shared/utilities/compare-strings";

export declare type NewsinglechoiceData = {
  type?: string;
  question: Paragraph[];
  questions: {
    text?: Paragraph;
    options: string[];
    correct: string;
  }[];
};

@Component({
  selector: "app-newsinglechoice",
  templateUrl: "./newsinglechoice.component.html",
  styleUrls: ["./newsinglechoice.component.css"],
})
export class NewsinglechoiceComponent implements TaskComponent, OnInit {
  @Input() data: NewsinglechoiceData;
  @Output() taskSubmitted: EventEmitter<boolean> = new EventEmitter();
  public answers: string[] = [];
  public isAnswered: boolean = false;
  private isCorrect: boolean[] = [];

  constructor() {}

  ngOnInit() {}

  submit() {
    this.isCorrect = [];
    let i = 0;
    for (let question in this.data.questions) {
      this.isCorrect.push(
        compareStrings(this.data.questions[i].correct, this.answers[i])
      );
      i++;
    }
    if (_.every(this.isCorrect)) {
      this.taskSubmitted.emit(true);
    } else {
      this.taskSubmitted.emit(false);
    }
    this.isAnswered = true;
  }

  setColor(i: number, option: string) {
    if (!this.isAnswered) {
      return "";
    }
    if (compareStrings(this.data.questions[i].correct, option)) {
      return "correct";
    }
  }
}
