import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  Inject,
  ViewEncapsulation,
  ChangeDetectorRef,
} from "@angular/core";
import { TaskComponent } from "../task";
import { DOCUMENT } from "@angular/common";
import * as _ from "lodash";
import { matchAll } from "@shared/utilities";
import { compareStringAndArray } from "@shared/utilities/compare-strings";
import { Paragraph } from "src/app/dvz/dikobraz-se-uci-anglicky/models/task.model";

declare type Image = {
  imageUrl: string;
  rowScheme: string;
};

export declare type PicturemultiinsertData = {
  type?: string;
  question: Paragraph[];
  images: Image[];
};

@Component({
  selector: "app-picturemultiinsert",
  templateUrl: "./picturemultiinsert.component.html",
  styleUrls: ["./picturemultiinsert.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class PicturemultiinsertComponent implements TaskComponent, OnInit {
  @Input() data: PicturemultiinsertData;
  @Output() taskSubmitted: EventEmitter<boolean> = new EventEmitter();
  private document;
  public isAnswered = false;
  public isEnd = false;
  public isCorrect: boolean[] = [];
  public filledTemplate = "";
  public questions: {
    correct: string[];
    hint: string;
    id: string;
  }[] = [];
  public currentImage: Image;
  public currentIndex: number = 0;

  constructor(
    @Inject(DOCUMENT) document,
    private changeRef: ChangeDetectorRef
  ) {
    this.document = document;
  }

  ngOnInit() {
    this.currentImage = this.data.images[0];
    this.createGaps();
  }

  next() {
    this.isAnswered = false;
    this.currentIndex++;
    this.currentImage = this.data.images[this.currentIndex];
    if (!this.data.images[this.currentIndex + 1]) {
      this.isEnd = true;
    }
    this.createGaps();
  }

  submit() {
    for (let answerIndex in this.questions) {
      let answer = this.questions[answerIndex];
      let userAnswer: string = this.getAnswer(parseInt(answerIndex));
      this.isCorrect.push(compareStringAndArray(userAnswer, answer.correct));
      let inputEl = this.document.getElementById(
        this.questions[answerIndex].id
      );
      const newEl = this.document.createElement("span");
      newEl.classList.add(
        compareStringAndArray(userAnswer, answer.correct)
          ? "correct"
          : "incorrect",
        "answer"
      );
      newEl.innerHTML = userAnswer;
      if (this.questions[answerIndex].hint) {
        inputEl.parentNode.removeChild(inputEl.nextSibling);
      }
      inputEl.parentNode.replaceChild(newEl, inputEl);
      if (!compareStringAndArray(userAnswer, answer.correct)) {
        const correctAnswer = this.document.createElement("span");
        correctAnswer.classList.add("correct-answer");
        correctAnswer.innerHTML =
          "( správná odpověď: " + answer.correct.join(", ") + ")";
        newEl.parentNode.insertBefore(correctAnswer, newEl.nextSibling);
      }
    }
    if (this.isEnd) {
      if (_.every(this.isCorrect)) {
        this.taskSubmitted.emit(true);
      } else {
        this.taskSubmitted.emit(false);
      }
    }
    this.isAnswered = true;
  }

  getAnswer(id: number) {
    if (!this.document.getElementById(this.questions[id].id)) {
      return null;
    }
    this.document.getElementById(this.questions[id].id).onchange = (e) => {
      if (this.changeRef) {
        this.changeRef.detectChanges();
      }
    };
    return this.document.getElementById(this.questions[id].id).value;
  }

  isAllFilled() {
    let allFilled = true;
    if (!this.data) {
      return false;
    }
    for (let answerIndex in this.questions) {
      if (!this.getAnswer(parseInt(answerIndex))) {
        allFilled = false;
      }
    }
    return allFilled;
  }

  createGaps() {
    let template: string;
    template = this.currentImage.rowScheme;
    this.questions = [];
    let gapMatches = matchAll(/\{#INSERT:([^}*]+)\}/g, template);
    for (let i in gapMatches) {
      let questionTemplate = gapMatches[i][0]
        .replace("{#INSERT:", "")
        .replace("}", "");
      let correct = questionTemplate.split(":")[0].split(",");
      let hint =
        questionTemplate.split(":").length > 1
          ? `(${questionTemplate.split(":")[1]})`
          : "";
      this.questions.push({
        correct: correct.map((correct: string) => correct.trim()),
        hint: hint,
        id: "insert-" + i,
      });
    }
    let gappedText = template;
    for (let i in this.questions) {
      const hint = `${this.questions[i].hint}`;
      gappedText = gappedText.replace(
        gapMatches[i][0],
        '<input class="answer" id="' +
          this.questions[i].id +
          '">' +
          "</input><span>" +
          hint.trim() +
          "</span>"
      );
    }
    this.filledTemplate = gappedText;
  }
}
