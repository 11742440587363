import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'dvz-header',
  templateUrl: './dvz-header.component.html',
  styleUrls: ['./dvz-header.component.css']
})
export class DvzHeaderComponent implements OnInit {

  @Input() title: string;
  @Input() link: string;
  @Input() prev: string;
  @Input() next: string;
  @Input() prevTooltip: string;
  @Input() nextTooltip: string;
  @Input() hasNext: boolean;
  @Input() hasPrev: boolean;
  @Input() tooltip: string;
  @Output() nextFn = new EventEmitter();
  @Output() prevFn = new EventEmitter();
  @Input() prevParams;
  @Input() nextParams;

  constructor() { }

  ngOnInit() {
  }

}
